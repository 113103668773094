import useCurrencyConverted from '~/client/hooks/currency/useCurrencyConverted';
import useWalletCurrency from '~/client/hooks/currency/useWalletCurrency';
import useFeature from '~/client/hooks/feature/useFeature';
import useUserBalance from '~/client/hooks/user/useUserBalance';

const useShowCryptoBannerLoggedIn = (): boolean => {
  const isNoCryptoBannerVisible = useFeature('isNoCryptoBannerVisible');
  const currency = useWalletCurrency();
  const { realBalance } = useUserBalance();

  const convertedAmount = useCurrencyConverted({
    amount: realBalance,
    from: currency,
    to: 'BTC',
  });

  if (convertedAmount === undefined) {
    return true;
  }

  return convertedAmount * 1000 < 1 && Boolean(isNoCryptoBannerVisible);
};

export default useShowCryptoBannerLoggedIn;
