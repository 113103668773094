import { Button } from '@heathmont/moon-components';
import Translate from '~/client/shared/i18n/Translate';
import NextLink from '~/client/shared/NextLink';
import BcIoLoggedOutBannerCard from '..';

type Props = {
  content: string | JSX.Element | JSX.Element[];
  isMobile: boolean;
};

const BannerContentContainer: FC<Props> = ({ content, isMobile }) => {
  if (isMobile) {
    return (
      <>
        <BcIoLoggedOutBannerCard isMobile={isMobile} />
        <NextLink className="mt-4" href="/register">
          <Button variant="primary" fullWidth>
            <Translate
              className="text-moon-16 font-normal"
              id="profile.create_account"
            />
          </Button>
        </NextLink>
        {content}
      </>
    );
  }
  return (
    <>
      {content}
      <BcIoLoggedOutBannerCard />
    </>
  );
};

export default BannerContentContainer;
