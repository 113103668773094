import { mergeClassnames } from '@heathmont/moon-core-tw';

const MainBannerContainer: FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <div
    className={mergeClassnames(
      'z-2 grid w-full grid-rows-[auto_10.75rem] rounded-moon-s-md 3xl:rounded-none 3xl:rounded-l-moon-s-md landscape:height2xs:h-full landscape:height2xs:grid-rows-[initial] landscape:height2xs:pb-4 landscape:height2xs:pl-2.5',
      'md:grid-cols-[27.5rem_minmax(0,1fr)] md:grid-rows-none md:gap-4 md:rem:pl-[48px] 2xl:rem:pl-[88px]',
      className,
    )}
  >
    {children}
  </div>
);

export default MainBannerContainer;
