import useIsLoggedIn from '~/client/hooks/user/useIsLoggedIn';
import NoCryptoBannerViewContainer from './NoCryptoBannerViewContainer';
import useShowCryptoBannerLoggedIn from '../hooks/useShowBanner';
import NoCryptoBannerContainer from '../NoCryptoBannerContainer';
import NoCryptoBannerLoggedIn from '../NoCryptoBannerLoggedIn';
import NoCryptoBannerLoggedOut from '../NoCryptoBannerLoggedOut';

const NoCryptoBannerView = () => {
  const isLoggedIn = useIsLoggedIn();

  const isShow = useShowCryptoBannerLoggedIn();

  if (isLoggedIn && !isShow) {
    return null;
  }

  return (
    <NoCryptoBannerViewContainer>
      <NoCryptoBannerContainer>
        {isLoggedIn && <NoCryptoBannerLoggedIn />}
        {!isLoggedIn && <NoCryptoBannerLoggedOut />}
      </NoCryptoBannerContainer>
    </NoCryptoBannerViewContainer>
  );
};

export default NoCryptoBannerView;
