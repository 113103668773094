import useAgentData from '~/client/hooks/useAgentData';
import useLanguage from '~/client/hooks/useLanguage';
import useGameNameLanguage from '~/client/hooks/user/useGameNameLanguage';
import useSite from '~/client/hooks/useSite';
import { useThemeGamesListQuery } from '../graphql/ThemeGamesListQuery.generated';

const useGames = (theme?: string) => {
  const { isMobile } = useAgentData();

  const { site } = useSite();
  const filter = { site, theme };
  const language = useGameNameLanguage();
  const { code } = useLanguage();
  const popular = -1;

  const variables = {
    first: isMobile ? 5 : 7,
    filter,
    language,
    hrefPrefix: '/play',
    sort: { popular, language: code },
  };

  const { data } = useThemeGamesListQuery({
    variables,
  });

  const games = data?.casino?.games?.edges;
  return { games };
};

export default useGames;
