import { mergeClassnames } from '@heathmont/moon-core-tw';
import useAgentData from '~/client/hooks/useAgentData';
import useSite from '~/client/hooks/useSite';
import TailwindThemeProvider from '~/client/shared/ThemeProviders/TailwindThemeProvider';
import Content from './Content';
import type Props from '../Props';

const PromoBannerContent: FC<Props> = ({ promotion, preload, index }) => {
  const { isMobile } = useAgentData();
  const { isBitcasino } = useSite();
  const borderRadiuses =
    'rounded-moon-s-md sm:rounded-b-none 3xl:rounded-l-moon-s-md 3xl:rounded-r-none landscape:height2xs:rounded-moon-s-md';

  if (isMobile) {
    return (
      <Content
        promotion={promotion}
        preload={preload}
        index={index}
        borderRadiuses={borderRadiuses}
      />
    );
  }

  return (
    <TailwindThemeProvider colorScheme="dark" className="w-full">
      <div
        className={mergeClassnames(borderRadiuses, !isBitcasino && 'bg-gohan')}
      >
        <Content
          promotion={promotion}
          preload={preload}
          index={index}
          borderRadiuses={borderRadiuses}
        />
      </div>
    </TailwindThemeProvider>
  );
};

export default PromoBannerContent;
