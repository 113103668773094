'use client';
import CasinoBoost from '~/client/features/CasinoBoost';
import PrizePromo from '~/client/features/PrizePromo';
import TopPicksForYou from '~/client/features/RecommendedGames';
import Tournaments from '~/client/features/Tournaments';
import useAgentData from '~/client/hooks/useAgentData';
import useIsLoggedIn from '~/client/hooks/user/useIsLoggedIn';
import NoCryptoBanner from '~/client/shared/AbTests/NoCryptoBanner';
import AdvantagesOfBitcoinGambling from './AdvantagesOfBitcoinGambling';
import Banner from './Banner';
import BestGameOfTheWeek from './BestGameOfTheWeek';
import BestLiveGames from './BestLiveGames';
import BestSlotGames from './BestSlotGames';
import BestTableGames from './BestTableGames';
import BlogNews from './BlogNews';
import ClubhouseGames from './ClubhouseGames';
import CrashGames from './CrashGames';
import GameCategories from './GameCategories';
import GameProviders from './GameProviders';
import NewReleases from './NewReleases ';
import Testimonials from './Testimonials';
import Themes from './Themes';

const SportsbetHome: FC = () => {
  const { isMobile, isLoggedIn } = useHome();

  return (
    <div className="flex flex-col gap-8">
      <Banner />
      <NoCryptoBanner />
      <BestLiveGames />
      <BestSlotGames />
      <CasinoBoost module="home" />
      <NewReleases />
      <ClubhouseGames />
      {isMobile && <GameCategories />}
      {isMobile && <BestGameOfTheWeek />}
      {isMobile && <Tournaments module="home" />}
      {isLoggedIn && <TopPicksForYou />}
      <Themes />
      <CrashGames />
      <BestTableGames />
      <GameProviders />
      {!isLoggedIn && <Testimonials />}
      {!isLoggedIn && <BlogNews />}
      <AdvantagesOfBitcoinGambling />
      {isLoggedIn && <PrizePromo />}
    </div>
  );
};

const useHome = () => {
  const { isMobile } = useAgentData();
  const isLoggedIn = useIsLoggedIn();
  return { isMobile, isLoggedIn };
};

export default SportsbetHome;
