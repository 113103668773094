import useGameCardSize from '~/client/shared/game/GameCard/useGameCardSize';
import GameCarousel from '~/client/shared/game/GameCarousel';
import Lazy from '~/client/shared/LazyLoad/Lazy';
import CarouselPlaceholder from '~/client/shared/LazyLoad/Placeholder/CaroulselPlaceholder';

const CrashGames: FC = () => {
  const titleId = 'casino.crash_games';
  const href = '/games/crash-games';
  const { width, height } = useGameCardSize();
  const placeholder = (
    <CarouselPlaceholder
      width={width}
      height={height}
      titleId={titleId}
      href={href}
    />
  );
  return (
    <Lazy placeholder={placeholder}>
      <GameCarousel
        menuItem="crash-games"
        titleId={titleId}
        href={href}
        placeholder={placeholder}
        sourcePage="front_page"
        sliderName="crash_games"
      />
    </Lazy>
  );
};

export default CrashGames;
