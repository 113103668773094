const optimisticProviders: {
  __typename?: 'CasinoMenuItemEdge' | undefined;
  node?:
    | {
        __typename: 'CasinoMenuItem';
        id: string;
        name?: string | undefined;
        handle?: string | undefined;
        games?:
          | {
              __typename?: 'CasinoGameConnection' | undefined;
              edges?:
                | {
                    node?: {
                      id: string;
                    };
                  }[]
                | undefined;
            }
          | undefined;
      }
    | undefined;
}[] = [
  {
    node: {
      id: 'Q2FzaW5vTWVudUl0ZW06aFdaOVpaZjdvTjhmd3pFR1k=',
      name: 'Evolution',
      handle: 'evolution',
      games: {
        edges: [
          {
            node: {
              id: 'Q2FzaW5vR2FtZTo4M2ZZbmFLUXBGb3JrUlI4Ug==',
            },
          },
        ],
      },
      __typename: 'CasinoMenuItem',
    },
  },
  {
    node: {
      id: 'Q2FzaW5vTWVudUl0ZW06QjhSck5YRUwyV3dKNVQ3NXQ=',
      name: 'Bombay Live',
      handle: 'bombaylive',
      games: {
        edges: [
          {
            node: {
              id: 'Q2FzaW5vR2FtZTptNHhDOENMeXNNTExpaUJaag==',
            },
          },
        ],
      },
      __typename: 'CasinoMenuItem',
    },
  },
  {
    node: {
      id: 'Q2FzaW5vTWVudUl0ZW06dWhUNHdndlJ5SFhxcWJBb04=',
      name: 'OneTouch',
      handle: 'onetouch',
      games: {
        edges: [
          {
            node: {
              id: 'Q2FzaW5vR2FtZTo1a0s5ZVJ3WWRTTUdSQndjOQ==',
            },
          },
        ],
      },
      __typename: 'CasinoMenuItem',
    },
  },
  {
    node: {
      id: 'Q2FzaW5vTWVudUl0ZW06NUROOGdNTWc5b2hDa1l0TDM=',
      name: 'Pragmatic Play',
      handle: 'pragmaticplay',
      games: {
        edges: [
          {
            node: {
              id: 'Q2FzaW5vR2FtZTpia0RQYzRBajNiRnlBUUVORA==',
            },
          },
        ],
      },
      __typename: 'CasinoMenuItem',
    },
  },
  {
    node: {
      id: 'Q2FzaW5vTWVudUl0ZW06M3JoSzJRMmVDakp5YTVuQU0=',
      name: "Play'n Go",
      handle: 'playngo',
      games: {
        edges: [
          {
            node: {
              id: 'Q2FzaW5vR2FtZTpRakN4cmFYYmNOUWhncHVDNA==',
            },
          },
        ],
      },
      __typename: 'CasinoMenuItem',
    },
  },
  {
    node: {
      id: 'Q2FzaW5vTWVudUl0ZW06REp1aWYyZ2R4V2RkZk0zQVI=',
      name: 'Push Gaming',
      handle: 'pushgaming',
      games: {
        edges: [
          {
            node: {
              id: 'Q2FzaW5vR2FtZTpaOThIYWpqSG01Y3E3Q0ZZTQ==',
            },
          },
        ],
      },
      __typename: 'CasinoMenuItem',
    },
  },
  {
    node: {
      id: 'Q2FzaW5vTWVudUl0ZW06M3ZXSEJiYUxSd1IyOGJ1QVM=',
      name: 'Nolimit City',
      handle: 'nolimitcity',
      games: {
        edges: [
          {
            node: {
              id: 'Q2FzaW5vR2FtZTpZN2hCSE5QVGE3ZGhhU2NDNg==',
            },
          },
        ],
      },
      __typename: 'CasinoMenuItem',
    },
  },
  {
    node: {
      id: 'Q2FzaW5vTWVudUl0ZW06Sk4yakJRa0Q2OHc4ZFFvN1M=',
      name: 'Hacksaw Gaming',
      handle: 'hacksawgaming',
      games: {
        edges: [
          {
            node: {
              id: 'Q2FzaW5vR2FtZTp0YUpBS2p5SEJxMm5MOUdlNw==',
            },
          },
        ],
      },
      __typename: 'CasinoMenuItem',
    },
  },
  {
    node: {
      id: 'Q2FzaW5vTWVudUl0ZW06SG5GZXhaRExMTWRROXozdUs=',
      name: 'Caleta',
      handle: 'caleta',
      games: {
        edges: [
          {
            node: {
              id: 'Q2FzaW5vR2FtZTo2bXI1b3B1ekhEanZuM1F5Ug==',
            },
          },
        ],
      },
      __typename: 'CasinoMenuItem',
    },
  },
  {
    node: {
      id: 'Q2FzaW5vTWVudUl0ZW06RXZvTXRISHV1cW5vcUs4Zmc=',
      name: 'Red Tiger',
      handle: 'red-tiger',
      games: {
        edges: [
          {
            node: {
              id: 'Q2FzaW5vR2FtZTplR3BlZkFZS3B3Zm5Dc0Rzcw==',
            },
          },
        ],
      },
      __typename: 'CasinoMenuItem',
    },
  },
];

export default optimisticProviders;
