import Img from '~/client/shared/images/Img';
import Title from './Title';

type Props = { img?: string; title?: string };

const Top: FC<Props> = ({ img = '', title = '' }) => (
  <div className="relative leading-[0] text-goten">
    <Title title={title} />
    <Img
      src={img}
      width={384}
      height={300}
      fit="crop"
      className="h-auto w-full"
    />
  </div>
);

export default Top;
