import useAgentData from '~/client/hooks/useAgentData';
import applyImgixToUrl from '~/client/shared/images/imgix/applyImgixToUrl';
import useAppContext from '~/shared/context/AppContext/useAppContext';

type Props = {
  img: string;
  video: string;
};

const BannerVideo: FC<Props> = ({ img, video }) => {
  const { height, bannerImg } = useBannerVideo(img);
  return (
    <div className="right-0 flex justify-end">
      <video height={height} autoPlay loop muted poster={bannerImg} playsInline>
        <source type="video/mp4" src={video} />
      </video>
    </div>
  );
};

const useBannerVideo = (img: string) => {
  const { isMobile } = useAgentData();
  const { domain } = useAppContext();
  const height = isMobile ? 282 : 514;
  const bannerImg = applyImgixToUrl({
    cdnDomain: domain,
    src: img,
    h: height,
  });
  return { height, bannerImg };
};

export default BannerVideo;
