import formatNumber from './helpers/formatNumber';
import Title from './Title';
import Value from './Value';
import type bannerType from '../../types/bannerType';
import type BoxType from '../BoxType';

type Props = {
  type: BoxType;
  value: number;
  color?: string;
  bgColor?: string;
  lowCaseTitle?: boolean;
  bannerType: bannerType;
};

const Field: FC<Props> = ({
  type,
  value,
  color,
  bgColor,
  lowCaseTitle,
  bannerType,
}) => (
  <div className="grid items-center">
    <Value color={color} bgColor={bgColor} bannerType={bannerType}>
      {formatNumber(value)}
    </Value>
    <Title
      color={color}
      bannerType={bannerType}
      type={type}
      lowCaseTitle={lowCaseTitle}
    />
  </div>
);

export default Field;
