import Lazy from '~/client/shared/LazyLoad/Lazy';
import CarouselPlaceholder from '~/client/shared/LazyLoad/Placeholder/CaroulselPlaceholder';
import GameCategoriesView from './GameCategories';

const placeholder = (
  <CarouselPlaceholder
    width={100}
    height={140}
    length={15}
    showGameInfo={false}
  />
);

const GameCategories = () => (
  <Lazy placeholder={placeholder}>
    <GameCategoriesView placeholder={placeholder} />
  </Lazy>
);

export default GameCategories;
