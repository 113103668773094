import useAgentData from '~/client/hooks/useAgentData';

const useThemeSizes = () => {
  const { isDesktop, isTablet } = useAgentData();
  if (isDesktop) {
    return sizes.desktop;
  }
  if (isTablet) {
    return sizes.tablet;
  }
  return sizes.mobile;
};

const sizes = {
  mobile: {
    card: { w: 320, h: 232 },
  },
  tablet: {
    card: { w: 496, h: 288 },
  },
  desktop: {
    card: { w: 496, h: 288 },
  },
};

export default useThemeSizes;
