import useFeature from '~/client/hooks/feature/useFeature';
import PopularGamesWithLiveRtp from './PopularGamesWithLiveRtp';
import TrendingGames from './TrendingGames';

const PopularGames = () => {
  const isPopularEnabled = useFeature('isPopularGamesEnabled');
  const isLiveRtpEnabled = useFeature('rtpLive');
  if (!isPopularEnabled) {
    return null;
  }
  if (isLiveRtpEnabled) {
    return <PopularGamesWithLiveRtp />;
  }
  return <TrendingGames />;
};

export default PopularGames;
