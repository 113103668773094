import useLanguage from '~/client/hooks/useLanguage';
import useCurrentUser from '~/client/hooks/user/useCurrentUser';
import getGameLanguage from '~/shared/graphql/game/helpers/getGameLanguage';
import useFeatures from '../feature/useFeatures';

const useGameNameLanguage = (): string => {
  const { profile } = useCurrentUser();
  const { code } = useLanguage();
  const featureFlags = useFeatures();
  const language = getGameLanguage(code, featureFlags, profile);
  return language;
};

export default useGameNameLanguage;
