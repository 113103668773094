import useAgentData from '~/client/hooks/useAgentData';
import convertHexToRGBA from '~/client/shared/convertHexToRGBA';
import type { ThemeFragment } from '~/shared/graphql/home/ThemesQuery.generated';

const useThemeProps = (theme: ThemeFragment) => {
  const { isDesktop } = useAgentData();
  const { color } = parseBackground(theme?.background);
  const bgColor = convertHexToRGBA(color, 0.08);
  const bgColorHover = convertHexToRGBA(color, 0.16);

  return {
    bgColor,
    bgColorHover,
    color,
    isDesktop,
  };
};

const parseBackground = (background = '{}') => {
  const backgroundObj = JSON.parse(background);
  const color = backgroundObj['background-color'];
  return { color };
};

export default useThemeProps;
