import useGameCardSize from '~/client/shared/game/GameCard/useGameCardSize';
import GameCarousel from '~/client/shared/game/GameCarousel';
import Lazy from '~/client/shared/LazyLoad/Lazy';
import CarouselPlaceholder from '~/client/shared/LazyLoad/Placeholder/CaroulselPlaceholder';

const BestLiveGames = () => {
  const titleId = 'general.best_live_casino_games';
  const href = '/categories/live-casino';
  const { width, height } = useGameCardSize(true);
  const placeholder = (
    <CarouselPlaceholder
      width={width}
      height={height}
      length={6}
      titleId={titleId}
      href={href}
    />
  );
  return (
    <Lazy placeholder={placeholder}>
      <GameCarousel
        big
        menuItem="live-dealer"
        titleId={titleId}
        href={href}
        placeholder={placeholder}
        sourcePage="front_page"
        sliderName="best_live_games"
      />
    </Lazy>
  );
};

export default BestLiveGames;
