import NextLink from '~/client/shared/NextLink';
import Image from './Image';
import useImageWrapper from './useImageWrapper';
import type Node from '../Node';

type Props = {
  node: Node;
  isMobile?: boolean;
  preload?: boolean;
};

const ImageWrapper: FC<Props> = ({ node, isMobile, preload }) => {
  const { attribs, image, href, handleClick } = useImageWrapper(node);
  if (!image) {
    return null;
  }

  return (
    <NextLink
      href={href}
      onClick={handleClick}
      className={`${attribs?.class} main-banner-image-logout`}
    >
      <Image node={image} isMobile={isMobile} preload={preload} />
    </NextLink>
  );
};

export default ImageWrapper;
