import CustomCarousel from '~/client/shared/Carousel/CustomCarousel';
import PageSection from '~/client/shared/PageSection';
import getIdFromString from '~/shared/utils/getIdFromString';
import isListEmpty from '~/shared/utils/isListEmpty';
import Card from './Card';
import useTestimonials from './useTestimonials';

type Props = { placeholder?: JSX.Element };

const Testimonials: FC<Props> = ({ placeholder }) => {
  const { testimonials, loading } = useTestimonials();
  if (loading) {
    return <>{placeholder}</>;
  }
  if (isListEmpty(testimonials)) {
    return null;
  }
  const items = testimonials?.map((testimonial) => {
    if (!testimonial) {
      return null;
    }
    const key = getIdFromString(testimonial);

    return <Card key={key} content={testimonial} />;
  });
  return (
    <PageSection titleId="casino.testimonials" href="/blog" id="testimonials">
      <CustomCarousel items={items ?? []} />
    </PageSection>
  );
};

export default Testimonials;
