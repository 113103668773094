import CustomCarousel from '~/client/shared/Carousel/CustomCarousel';
import PageSection from '~/client/shared/PageSection';
import type { GameProviderFragment } from '~/shared/graphql/home/GameProvidersQuery.generated';
import isListEmpty from '~/shared/utils/isListEmpty';
import Provider from './Provider';
import useGameProviders from './useGameProviders';

type Props = { placeholder?: JSX.Element };

const GameProvidersView: FC<Props> = ({ placeholder }) => {
  const { providers, loading } = useGameProviders();
  if (loading) {
    return <>{placeholder}</>;
  }
  if (isListEmpty(providers)) {
    return null;
  }
  const items = providers.reduce((acc, { node }) => {
    if (!node || !hasGames(node)) {
      return acc;
    }
    return [...acc, <Provider key={node.id} provider={node} />];
  }, [] as JSX.Element[]);

  return (
    <PageSection id="providers">
      <CustomCarousel items={items ?? []} />
    </PageSection>
  );
};

const hasGames = (provider?: GameProviderFragment) => {
  const gamesLength = provider?.games?.edges?.length ?? 0;
  return gamesLength > 0;
};

export default GameProvidersView;
