import useCashierLink from '~/client/hooks/feature/useCashierLink';
import useAgentData from '~/client/hooks/useAgentData';

const useNoCryptoBannerLoggedIn = () => {
  const { isMobile } = useAgentData();
  const imgSrc = isMobile ? 'mobile' : 'desktop';
  const { onClick: onClickFiatBanner } = useCashierLink({
    cashierPath: 'deposit_options-banking',
  });
  const { onClick: onClickCryptoBanner } = useCashierLink({
    cashierPath: 'deposit_options',
  });

  return {
    isMobile,
    imgSrc,
    onClickFiatBanner,
    onClickCryptoBanner,
  };
};

export default useNoCryptoBannerLoggedIn;
