import Container from './styles/Container';
import type bannerType from '../../../types/bannerType';

type Props = {
  color?: string;
  bgColor?: string;
  bannerType: bannerType;
};

const Value: FC<Props> = ({
  children,
  color = 'text-bulma',
  bgColor = 'bg-gohan',
  bannerType,
}) => (
  <Container color={color} bgColor={bgColor} bannerType={bannerType}>
    {children}
  </Container>
);

export default Value;
