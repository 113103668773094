import Translate from '~/client/shared/i18n/Translate';

type Props = {
  descriptionId: string;
};

const NoCryptoBannerDescription: FC<Props> = ({ descriptionId }) => (
  <Translate
    id={descriptionId}
    className="text-sm text-white text-opacity-50 3xl:text-base"
  />
);

export default NoCryptoBannerDescription;
