import { useCallback, useRef, useState } from 'react';
import getCdnResource from '~/shared/cdn/getCdnResource';

const useVideoResource = (src: string) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isStopped, setStopped] = useState(true);
  const videoSrc = getCdnResource(src);

  const handleClick = useCallback(() => {
    if (videoRef.current?.paused) {
      setStopped(false);
      videoRef.current.play();
    } else {
      setStopped(true);
      videoRef.current?.pause();
    }
  }, []);

  const handleEnded = useCallback(() => setStopped(true), []);

  return { videoRef, isStopped, videoSrc, handleClick, handleEnded };
};

export default useVideoResource;
