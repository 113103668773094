import dynamic from 'next/dynamic';
import useFeature from '~/client/hooks/feature/useFeature';

const DepositorsReminderPopup = dynamic(
  () => import('./DepositorsReminderPopup'),
  {
    ssr: false,
  },
);

const DepositorsReminder = () => {
  const depositorsReminderEnabled = useFeature('depositorsReminder');
  if (!depositorsReminderEnabled) {
    return null;
  }
  return <DepositorsReminderPopup />;
};

export default DepositorsReminder;
