import Bottom from './Bottom';
import Top from './Top';
import useBestGameOfTheWeek from './useBestGameOfTheWeek';

type Props = { placeholder: JSX.Element };

const BestGameOfTheWeek: FC<Props> = ({ placeholder }) => {
  const { href, img, title, bgColor, name, provider, loading } =
    useBestGameOfTheWeek();
  if (loading) {
    return placeholder;
  }
  return (
    <section className="overflow-hidden rounded-moon-s-xs">
      <Top img={img} title={title} />
      <Bottom
        href={href}
        bgColor={bgColor}
        name={name}
        provider={provider?.[0]?.name}
      />
    </section>
  );
};

export default BestGameOfTheWeek;
