import useAgentData from '~/client/hooks/useAgentData';
import NextLink from '~/client/shared/NextLink';
import TailwindThemeProvider from '~/client/shared/ThemeProviders/TailwindThemeProvider';
import FirstDepositBanner from './FirstDepositBanner';
import firstDepositBannerQueryFilter from './FirstDepositBanner/constant/firstDepositBannerQueryFilter';
import PromoBannerContent from './PromoBannerContent';
import type Props from './Props';

const PromoBanner: FC<Props> = ({ promotion, preload, index }) => {
  const { link, handle = '' } = promotion ?? {};
  const { isMobile } = useAgentData();

  if (promotion?.handle === firstDepositBannerQueryFilter.handle) {
    return (
      <TailwindThemeProvider colorScheme="dark" className="w-full ">
        <FirstDepositBanner promotion={promotion} />
      </TailwindThemeProvider>
    );
  }

  if (isMobile) {
    return (
      <NextLink href={link || `/promotions/${handle}`} className="w-full">
        <PromoBannerContent
          promotion={promotion}
          preload={preload}
          index={index}
        />
      </NextLink>
    );
  }
  return (
    <PromoBannerContent promotion={promotion} preload={preload} index={index} />
  );
};

export default PromoBanner;
