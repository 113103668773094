import useSite from '~/client/hooks/useSite';
import CollapsedCms from '~/client/shared/cms/CollapsedCms';
import Lazy from '~/client/shared/LazyLoad/Lazy';
import Placeholder from '~/client/shared/LazyLoad/Placeholder';

const placeholder = <Placeholder height={300} />;

const AdvantagesOfBitcoinGambling: FC = () => {
  const { isBitcasino } = useSite();
  const handle = isBitcasino
    ? 'advantages-of-bitcoin-gaming'
    : 'the-advantages-of-bitcoin-gaming';
  return (
    <Lazy placeholder={placeholder}>
      <section>
        <CollapsedCms handle={handle} placeholder={placeholder} />
      </section>
    </Lazy>
  );
};

export default AdvantagesOfBitcoinGambling;
