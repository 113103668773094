'use client';
import PrizePromo from '~/client/features/PrizePromo';
import TopPicksForYou from '~/client/features/RecommendedGames';
import useIsLoggedIn from '~/client/hooks/user/useIsLoggedIn';
import NoCryptoBanner from '~/client/shared/AbTests/NoCryptoBanner';
import AdvantagesOfBitcoinGambling from './AdvantagesOfBitcoinGambling';
import Banner from './Banner';
import BombayClubGames from './BombayClubGames';
import CrashGames from './CrashGames';
import GameProviders from './GameProviders';
import GamesMenuItem from './GamesMenuItem';
import NewReleases from './NewReleases ';
import PopularGames from './PopularGames';

const LivecasinoHome: FC = () => {
  const isLoggedIn = useIsLoggedIn();
  return (
    <div className="flex flex-col gap-8">
      <Banner />
      <NoCryptoBanner />
      <BombayClubGames />
      <PopularGames />
      {isLoggedIn && <TopPicksForYou />}
      <GamesMenuItem menuItem="baccarat" />
      <GamesMenuItem menuItem="slots" />
      <GamesMenuItem menuItem="blackjack" />
      <GamesMenuItem menuItem="roulette" />
      <GamesMenuItem menuItem="live-game-shows" />
      <NewReleases />
      <CrashGames />
      <AdvantagesOfBitcoinGambling />
      <GameProviders />
      {isLoggedIn && <PrizePromo />}
    </div>
  );
};

export default LivecasinoHome;
