import type { TestimonialsQueryVariables } from '../TestimonialsQuery.generated';

const getTestimonialsVariables = (
  site: Site,
  language: string,
): TestimonialsQueryVariables => ({
  filter: {
    site,
    language,
    section: 'site-blocks',
    handle: 'testimonials',
  },
});

export default getTestimonialsVariables;
