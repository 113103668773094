import { mergeClassnames } from '@heathmont/moon-core-tw';
import Img from '~/client/shared/images/Img';
import NextLink from '~/client/shared/NextLink';
import getProviderIcon from '~/shared/cdn/getProviderIcon';
import useAppContext from '~/shared/context/AppContext/useAppContext';
import type { GameProviderFragment } from '~/shared/graphql/home/GameProvidersQuery.generated';
import sendInteractionToGtm from '~/shared/gtm/sendInteractionToGtm';

type Props = { provider: GameProviderFragment };

const Provider: FC<Props> = ({ provider }) => {
  const { colorScheme } = useAppContext();
  return (
    <div className="flex h-20 w-40 items-center justify-center px-4">
      <NextLink href={`/providers/${provider?.handle}`}>
        <Img
          className={mergeClassnames(
            'h-10 opacity-40 hover:opacity-100',
            colorScheme === 'dark' && 'invert',
          )}
          src={getProviderIcon(provider.handle)}
          alt={provider.name}
          height={40}
          onClick={() =>
            sendInteractionToGtm({
              eventCategory: 'Item_card',
              eventAction: 'Providers_list',
              eventInfo: provider?.handle,
            })
          }
        />
      </NextLink>
    </div>
  );
};

export default Provider;
