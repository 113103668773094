import { mergeClassnames } from '@heathmont/moon-core-tw';
import useThemeClassName from '~/client/hooks/theme/useThemeClassName';

type Props = {
  colorScheme: 'light' | 'dark';
  className?: string;
};

const TailwindThemeProvider: FC<Props> = ({
  children,
  colorScheme,
  className,
}) => {
  const themeClassName = useThemeClassName(colorScheme);

  return (
    <div className={mergeClassnames(themeClassName, className)}>{children}</div>
  );
};

export default TailwindThemeProvider;
