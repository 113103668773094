import useAgentData from '~/client/hooks/useAgentData';
import Button from '~/client/shared/Button';
import Translate from '~/client/shared/i18n/Translate';
import NextLink from '~/client/shared/NextLink';

type Props = {
  link?: string;
  handle?: string;
  text?: string;
  handleClick: () => void;
};

const PromoButtonLink: FC<Props> = ({ link, handleClick, handle, text }) => {
  const { isMobile } = useAgentData();
  const buttonText = text?.trim() || 'cms.promo.read_more';

  return (
    <NextLink href={link || `/promotions/${handle}`}>
      <Button
        onClick={handleClick}
        variant="primary"
        fullWidth={isMobile}
        className="font-normal"
      >
        <Translate id={buttonText} />
      </Button>
    </NextLink>
  );
};

export default PromoButtonLink;
