import parse, { DOMNode } from 'html-react-parser';
import Img from '~/client/shared/images/Img';

type Node = {
  attribs?: {
    href?: string;
    class?: string;
    src?: string;
    srcset?: string;
    style?: string;
    alt?: string;
    'data-action'?: string;
  };
  name?: string;
  children: DOMNode[];
};

const parseContent = (content: string) =>
  parse(content || '', { replace: (node) => replaceContent(cast(node)) });

const replaceContent = (node: Node) => {
  const { attribs, name } = node;

  if (name === 'img' && attribs?.class?.includes('star-yellow')) {
    const yellowStarSrc =
      '//cdn.coingaming.io/bitcasino/images/icons/star-yellow-small.svg';
    return (
      <Img
        width={12}
        height={12}
        className={attribs?.class}
        src={yellowStarSrc}
        alt={attribs?.alt}
      />
    );
  }
  if (name === 'img' && attribs?.class?.includes('star-gray')) {
    const grayStarSrc =
      '//cdn.coingaming.io/bitcasino/images/icons/star-gray.svg';
    return (
      <Img
        width={12}
        height={12}
        className={attribs?.class}
        src={grayStarSrc}
        alt={attribs?.alt}
      />
    );
  }
  return node;
};

const cast = (node: unknown) => node as Node;

export default parseContent;
