import { mergeClassnames } from '@heathmont/moon-core-tw';

const NoCryptoBannerViewContainer: FC = ({ children }) => (
  <div
    className={mergeClassnames(
      'hidden-scroll relative -mx-5 snap-x snap-mandatory',
      'overflow-x-auto overflow-y-hidden px-5 text-gohan',
      '[-webkit-overflow-scrolling:touch] [-ms-overflow-style:-ms-autohiding-scrollbar]',
      '[scrollbar-width:none] xl:-mx-0 xl:px-0',
    )}
  >
    {children}
  </div>
);

export default NoCryptoBannerViewContainer;
