import { mergeClassnames } from '@heathmont/moon-core-tw';
import useSidebarStore from '~/client/store/useSidebarStore';

type Props = {
  children?: React.ReactNode;
  classNames?: string;
  onBannerClick?: () => void;
};

const NoCryptoBannerCardContainer: FC<Props> = ({
  children,
  classNames,
  onBannerClick,
}) => {
  const { isOpen } = useSidebarStore();
  const xlSize = isOpen ? 'xl:basis-[50%]' : 'xl:w-1/2';

  return (
    <div
      onClick={onBannerClick}
      className={mergeClassnames(
        'relative box-content inline-flex cursor-pointer flex-row flex-wrap rounded-moon-s-sm bg-gradient-radial text-bulma',
        'w-[70vw] justify-center gap-y-2 p-3',
        'xl:m-0 xl:w-auto xl:justify-items-start xl:px-5 xl:py-6 3xl:px-3 3xl:py-3',
        '3xl:flex-shrink 3xl:flex-grow-0',
        xlSize,
        classNames,
      )}
    >
      {children}
    </div>
  );
};

export default NoCryptoBannerCardContainer;
