import useFeature from './useFeature';

const useBombayclubLink = () => {
  const isNewBombayClubPageEnabled = useFeature('isNewBombayClubPageEnabled');
  if (isNewBombayClubPageEnabled) {
    return '/bombayclub';
  }
  return '/categories/bombayclub';
};

export default useBombayclubLink;
