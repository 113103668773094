'use client';
import BitcasinoHome from './BitcasinoHome';
import DefaultHome from './DefaultHome';
import LivecasinoHome from './LivecasinoHome';
import SportsbetHome from './SportsbetHome';

type Props = { site: Site };

const Home: FC<Props> = ({ site }) => {
  switch (site) {
    case 'livecasino': {
      return <LivecasinoHome />;
    }
    case 'sportsbet':
    case 'sportsbetmx': {
      return <SportsbetHome />;
    }
    case 'bitcasino':
      return <BitcasinoHome />;
    default: {
      return <DefaultHome />;
    }
  }
};

export default Home;
