import getCurrency from '~/client/hooks/currency/helpers/getCurrency';
import type { CashierCurrency } from '~/schema/public';
import { useRateQuery } from './RateQuery.generated';
import useCurrencyDisplay from '../useCurrencyDisplay';

type Args = { from: CashierCurrency; to: CashierCurrency };

const useRate = ({ from = 'BTC', to }: Args) => {
  const fromCurrency = getCurrency(from);
  const toCurrency = getCurrency(to);
  const variables = { from: fromCurrency, to: toCurrency };
  const skip = fromCurrency === toCurrency;
  const { data } = useRateQuery({ variables, skip });
  const rate = data?.banking?.rate?.rate ?? 0;
  const { money } = useCurrencyDisplay(rate, {
    currency: to,
    maximumFractionDigits: 2,
  });
  return { money, rate };
};

export default useRate;
