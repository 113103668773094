import { Carousel, mergeClassnames } from '@heathmont/moon-core-tw';
import useLanguage from '~/client/hooks/useLanguage';
import styles from './styles.module.scss';

type CarouselProps = {
  items: React.ReactNode[];
  step?: number;
  scrollTo?: number;
  itemClassName?: string;
};

const BannerCarousel: React.FC<CarouselProps> = ({
  items,
  step,
  scrollTo,
  itemClassName,
}) => {
  const { locale } = useLanguage();
  const borderRadius = locale === 'ar' ? styles.bannerCarousel : '';
  return (
    <Carousel selectedIndex={scrollTo} step={step} className="overflow-hidden">
      <Carousel.Reel className="scroll-hidden">
        {items.map(
          (item: React.ReactNode, index: string | number | undefined) => (
            <Carousel.Item
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={mergeClassnames(
                'w-full snap-center',
                itemClassName,
                borderRadius,
              )}
            >
              {item}
            </Carousel.Item>
          ),
        )}
      </Carousel.Reel>
    </Carousel>
  );
};

export default BannerCarousel;
