import { LoggedInBannerQueryVariables } from '~/shared/graphql/home/LoggedInBannerQuery.generated';

const getLoggedInQueryVariables = (
  site: Site,
  language: string,
): LoggedInBannerQueryVariables => ({
  first: 3,
  filter: {
    site,
    language,
    section: 'panda-promotions',
  },
});

export default getLoggedInQueryVariables;
