import { Button } from '@heathmont/moon-core-tw';
import Translate from '~/client/shared/i18n/Translate';
import NextLink from '~/client/shared/NextLink';

type Props = { href: string; bgColor: string };

const PlayNowButton: FC<Props> = ({ href, bgColor }) => (
  <NextLink href={href}>
    <Button
      size="sm"
      style={{ color: bgColor }}
      className="bg-goten font-normal"
    >
      <Translate id="game.play_now" />
    </Button>
  </NextLink>
);

export default PlayNowButton;
