import { Modal } from '@heathmont/moon-core-tw';
import { ControlsClose } from '@heathmont/moon-icons-tw';
import Translate from '~/client/shared/i18n/Translate';
import Img from '~/client/shared/images/Img';
import Video from '~/client/shared/Video';
import { CDN_IMG_URL } from '~/shared/cdn/url';
import useNoCryptoBannerLoggedOut from './hooks/useNoCryptoBannerLoggedOut';
import NoCryptoBannerVideoThumbnail from './NoCryptoBannerVideo';
import NoCryptoBannerCard from '../NoCryptoBannerCard';
import NoCryptoBannerDescription from '../NoCryptoBannerCard/NoCryptoBannerDescription';
import NoCryptoBannerTitle from '../NoCryptoBannerCard/NoCryptoBannerTitle';

const NoCryptoBannerLoggedOut: FC = () => {
  const {
    onDepositBannerClick,
    depositImgSrc,
    openVideoModal,
    isVideoModalOpen,
    closeVideoModal,
    videoRef,
    videoSrc,
    handleEnded,
  } = useNoCryptoBannerLoggedOut();

  return (
    <>
      <NoCryptoBannerCard
        onBannerClick={onDepositBannerClick}
        classNames="from-[#432ebf] to-[#0a0524]"
      >
        <Img
          className="mb-3 xl:mb-0 xl:max-w-[10rem] 3xl:max-w-[14rem]"
          src={`${CDN_IMG_URL}/banners/deposit-${depositImgSrc}.png`}
        />
        <div className="flex flex-col gap-2 xl:ml-4 3xl:ml-8 3xl:flex-row">
          <NoCryptoBannerTitle titleId="no-crypto-banner.deposit-any-currency" />
          <NoCryptoBannerDescription descriptionId="no-crypto-banner.choose-currency" />
        </div>
      </NoCryptoBannerCard>
      <NoCryptoBannerCard
        onBannerClick={openVideoModal}
        classNames="from-[#3c0666] to-[#19051d]"
      >
        <NoCryptoBannerVideoThumbnail />
        <div className="flex flex-col gap-2 xl:ml-4 3xl:ml-8 3xl:flex-row">
          <NoCryptoBannerTitle titleId="no-crypto-banner.enjoy-crypto-benefits" />
          <NoCryptoBannerDescription descriptionId="no-crypto-banner.follow-crypto-tutorial" />
        </div>
      </NoCryptoBannerCard>
      <Modal open={isVideoModalOpen} onClose={closeVideoModal}>
        <Modal.Backdrop />
        <Modal.Panel className="-mx-2 w-screen max-w-[1094px] p-3">
          <div className="flex justify-between gap-x-3 p-3">
            <div>
              <Translate id="no-crypto-banner.video.modal-title" />
            </div>
            <ControlsClose
              onClick={closeVideoModal}
              className="cursor-pointer"
            />
          </div>
          <div>
            <Video
              videoRef={videoRef}
              src={videoSrc}
              onEnded={handleEnded}
              className="rounded-moon-s-sm"
              autoPlay
              controls
            />
          </div>
        </Modal.Panel>
      </Modal>
    </>
  );
};

export default NoCryptoBannerLoggedOut;
