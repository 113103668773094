import { useCallback } from 'react';
import useAgentData from '~/client/hooks/useAgentData';
import useIsRtl from '~/client/hooks/useIsRtl';
import useSidebarStore from '~/client/store/useSidebarStore';
import sendPromotionClickToGtm from '~/shared/gtm/sendPromotionClickToGtm';

type Args = { handle: string; largeImage: string; mediumImage: string };

const usePromoBanner = ({ handle, mediumImage }: Args) => {
  const { isMobile } = useAgentData();
  const rawSrc = mediumImage;
  const { image, video } = getMedia(rawSrc);
  const { isOpen } = useSidebarStore();
  const { isRtl } = useIsRtl();

  const handleClick = useCallback(
    () => sendPromotionClickToGtm({ handle, position: `promo-banner` }),
    [handle],
  );

  const showClaimReward = handle === 'welcome-offer-logged-in';

  return {
    handleClick,
    showClaimReward,
    isMobile,
    image,
    video,
    isSibebarOpen: isOpen,
    isRtl,
  };
};

const getMedia = (src: string) => {
  if (!src) {
    return { image: '', video: null };
  }
  const [image, video] = src.split('|');
  return { image, video };
};

export default usePromoBanner;
