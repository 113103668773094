import { mergeClassnames } from '@heathmont/moon-core-tw';
import type { ColorNames } from '@heathmont/moon-themes';
import useAgentData from '~/client/hooks/useAgentData';
import Lazy from '~/client/shared/LazyLoad/Lazy';
import NextLink from '~/client/shared/NextLink';
import useHover from './hooks/useHover';
import ThemeContent from '../ThemeContent';
import saveEventData from '@/client/helpers/trackEvent/umamiEvents/saveEventData';

type Props = {
  handle?: string;
  color: ColorNames;
  bgColor: string;
  bgColorHover?: string;
  index: number;
};

const ThemeCard: FC<Props> = ({
  handle = '',
  color,
  bgColor,
  bgColorHover,
  index,
}) => {
  const { isMobile, isDesktop } = useAgentData();
  const { background, onMouseOver, onMouseLeave } = useHover({
    bgColor,
    bgColorHover,
    isDesktop,
  });
  const testId = isMobile ? 'mobile-theme-card' : 'desktop-theme-card';

  const sendUmamiEvent = () => {
    const el = document.querySelector('#default-layout-body');

    saveEventData({
      event: 'launchGame',
      page: 'main',
      slider: {
        name: 'front_page',
        sliderTileIndex: index,
        screenVPos: el?.scrollTop || 0,
      },
    });
  };

  return (
    <NextLink
      href={`/themes/${handle}`}
      data-testid="theme-link"
      onClick={sendUmamiEvent}
    >
      <div
        className={mergeClassnames(
          'relative rounded-moon-s-md py-1 pl-[1.375rem] pr-1',
          isMobile && 'pb-4 pl-5 pr-4 pt-8',
        )}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        style={{ backgroundColor: background }}
        data-testid={testId}
      >
        <div className="min-w-[280px] min-h-[184px] md:min-w-[440px] md:min-h-[280px]">
          <Lazy>
            <ThemeContent themeHandle={handle} color={color} />
          </Lazy>
        </div>
      </div>
    </NextLink>
  );
};

export default ThemeCard;
