import CmsContainer from '~/client/shared/cms/CmsContainer/CmsView/CmsContainer';
import type CmsViewProps from '~/client/shared/cms/CmsContainer/CmsView/CmsViewProps';
import useCmsView from '~/client/shared/cms/CmsContainer/CmsView/useCmsView';
import parseContent from './helpers/parseContent';

const TestimonialsCmsView: FC<CmsViewProps> = (props) => {
  const { className, content } = props;
  const { node, isLoggedIn, handleClick } = useCmsView(props);
  return (
    <CmsContainer
      ref={node}
      className={className}
      loggedIn={isLoggedIn}
      onClick={handleClick}
    >
      {parseContent(content)}
    </CmsContainer>
  );
};

export default TestimonialsCmsView;
