import useFeature from '~/client/hooks/feature/useFeature';
import useIsLoggedIn from '~/client/hooks/user/useIsLoggedIn';
import useSite from '~/client/hooks/useSite';
import BcIoLoggedInBanner from './BcIoLoggedInBanner';
import LoggedInBanner from './LoggedInBanner';
import LoggedOutBanner from './LoggedOutBanner';

const Banner: FC = () => {
  const isBcIoLoggedInBanner = useFeature('isBcIoLoggedInBannerEnabled');
  const isLoggedIn = useIsLoggedIn();
  const { isSportsbet } = useSite();
  if (isLoggedIn || isSportsbet) {
    return isBcIoLoggedInBanner ? <BcIoLoggedInBanner /> : <LoggedInBanner />;
  }
  return <LoggedOutBanner />;
};

export default Banner;
