import { useState } from 'react';

type Props = {
  bgColor: string;
  bgColorHover: string | undefined;
  isDesktop: boolean;
};

const useHover = ({ bgColor, bgColorHover, isDesktop }: Props) => {
  const [background, setBackground] = useState(bgColor);

  const onMouseOver = () => {
    if (isDesktop && bgColorHover) {
      setBackground(bgColorHover);
    }
  };

  const onMouseLeave = () => {
    setBackground(bgColor);
  };

  return { background, onMouseOver, onMouseLeave };
};

export default useHover;
