import { useEffect, useState } from 'react';
import useIsInViewport from 'use-is-in-viewport';
import sendPromotionViewToGtm from '~/shared/gtm/sendPromotionViewToGtm';

type Args = { handle?: string; position?: string };

const usePromoImpression = ({ handle = '', position = '0' }: Args) => {
  const [promoImpression, setPromoImpression] = useState(false);
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 50 });

  useEffect(() => {
    if (!promoImpression && isInViewport) {
      setPromoImpression(true);
      sendPromotionViewToGtm({ handle, position });
    }
  }, [handle, isInViewport, position, promoImpression]);

  return targetRef;
};

export default usePromoImpression;
