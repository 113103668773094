import { mergeClassnames } from '@heathmont/moon-core-tw';
import useAgentData from '~/client/hooks/useAgentData';
import useSite from '~/client/hooks/useSite';
import Img from '~/client/shared/images/Img';
import getCdnImg from '~/shared/cdn/getCdnImg';

const filters: Partial<Record<Site, string>> = {
  sportsbet:
    '[filter:invert(10%)_sepia(100%)_saturate(500%)_hue-rotate(90deg)_brightness(125%)_contrast(60%)]',
  livecasino: 'contrast-75 saturate-[7]',
  empire: '[filter:sepia(89%)_saturate(2007%)_hue-rotate(307deg)]',
};

type Props = {
  index?: number;
};

const BannerBackground: FC<Props> = ({ index }) => {
  const { site, isBitcasino } = useSite();
  const { isMobile } = useAgentData();
  if (isBitcasino || isMobile) {
    return null;
  }
  return (
    <Img
      className={mergeClassnames(
        'hidden ltr:scale-[auto] rtl:scale-[-1] md:absolute md:top-0 md:block md:h-full md:w-auto',
        'md:ltr:left-1/2 md:ltr:right-auto md:rtl:left-auto md:rtl:right-1/2',
        filters[site],
      )}
      src={getBackgroundImg(index || 1)}
      alt=""
    />
  );
};

const getBackgroundImg = (index: number) =>
  getCdnImg(
    `backgrounds/main-banner-background-${index === 4 ? 2 : index + 1}.svg`,
  );

export default BannerBackground;
