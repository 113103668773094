const themeClassSite: Record<Site, string> = {
  bitcasino: 'bitcasino',
  livecasino: 'livecasino',
  sportsbet: 'sb',
  sportsbetmx: 'sb',
  empire: 'empire',
};

const getThemeClassName = (colorScheme: 'light' | 'dark', site: Site) =>
  `theme-${themeClassSite[site]}-${colorScheme}`;

export default getThemeClassName;
