import Lazy from '~/client/shared/LazyLoad/Lazy';
import CarouselPlaceholder from '~/client/shared/LazyLoad/Placeholder/CaroulselPlaceholder';
import TestimonialsView from './Testimonials';

const placeholder = (
  <CarouselPlaceholder width={292} height={256} showGameInfo={false} />
);

const Testimonials: FC = () => (
  <Lazy placeholder={placeholder}>
    <TestimonialsView placeholder={placeholder} />
  </Lazy>
);

export default Testimonials;
