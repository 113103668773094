import useAgentData from '~/client/hooks/useAgentData';
import useLanguage from '~/client/hooks/useLanguage';
import useCurrentUser from '~/client/hooks/user/useCurrentUser';
import useSite from '~/client/hooks/useSite';
import type { currencyConversion } from '~/schema/public';
import {
  UserLobbyRecommendationQueryVariables,
  useUserLobbyRecommendationQuery,
} from '../graphql/UserLobbyRecommendationQuery.generated';

const useRecommendedGamesCarousel = () => {
  const titleId = 'casino.top_picks_for_you';
  const href = '/games/recommended-games';
  const { code: language } = useLanguage();
  const { walletCurrency } = useCurrentUser();
  const { isMobile } = useAgentData();
  const { site } = useSite();
  const currency = walletCurrency as currencyConversion;

  const variables: UserLobbyRecommendationQueryVariables = {
    site,
    first: 12,
    language,
    currency,
    isMobile,
  };

  const { data, loading, error } = useUserLobbyRecommendationQuery({
    variables,
  });
  const edges = data?.casino?.hub88UserLobbyRecommendation?.edges;

  return { edges, loading, error, titleId, href };
};
export default useRecommendedGamesCarousel;
