import { mergeClassnames } from '@heathmont/moon-core-tw';
import Translate from '~/client/shared/i18n/Translate';
import type bannerType from '../../../types/bannerType';
import type BoxType from '../../BoxType';

type Props = {
  type: BoxType;
  lowCaseTitle?: boolean;
  color?: string;
  bannerType: bannerType;
};

const Title: FC<Props> = ({ type, lowCaseTitle, bannerType, color }) => (
  <span
    className={mergeClassnames(
      'text-center text-[8px] font-semibold leading-3 tracking-[0.5px]',
      lowCaseTitle ? 'lowercase' : 'uppercase',
      bannerType === 'secondary' ? 'text-trunks' : 'text-popo',
      color,
    )}
  >
    <Translate id={`timeleft.${type}`} />
  </span>
);

export default Title;
