import { useCallback } from 'react';
import useFeature from '~/client/hooks/feature/useFeature';
import useAgentData from '~/client/hooks/useAgentData';
import useIsLoggedIn from '~/client/hooks/user/useIsLoggedIn';
import useSite from '~/client/hooks/useSite';

type Args = {
  menuItems: string[];
};

const useArcadeGamesItem = () => {
  const icon = 'arcade';
  const { isMobile } = useAgentData();
  const { isSportsbet, isSbio, isSportsbetMx } = useSite();
  const isLoggedIn = useIsLoggedIn();
  const isArcadeGamesMenuItemEnabled = useFeature(
    'isArcadeGamesMenuItemEnabled',
  );
  const handle = 'arcade-games';
  const link = '/play/greenjade/arcade-games';
  const text = `casino.menu_item.${handle}`;
  const description = `casino.menu_item.${handle}_description`;
  const isSportsbetOnly = isSportsbet && !isSbio && !isSportsbetMx;
  const hideMenuItem =
    !isSportsbetOnly || (isLoggedIn && !isArcadeGamesMenuItemEnabled);

  const filterArcadeMenuItem = useCallback(
    ({ menuItems }: Args) => {
      if (
        isMobile ||
        !isSportsbetOnly ||
        (isLoggedIn && !isArcadeGamesMenuItemEnabled)
      ) {
        return menuItems?.filter((item) => item !== handle);
      }
      return menuItems;
    },
    [isArcadeGamesMenuItemEnabled, isMobile, isSportsbetOnly, isLoggedIn],
  );

  return {
    isArcadeGamesMenuItemEnabled,
    isMobile,
    hideMenuItem,
    handle,
    link,
    icon,
    text,
    description,
    filterArcadeMenuItem,
  };
};

export default useArcadeGamesItem;
