import useSiteConfig from '~/client/hooks/useSiteConfig';
import Img from '~/client/shared/images/Img';

type Props = Omit<ImgProps, 'src' | 'preload'> & {
  icon: string;
  ext?: '.svg' | '.png' | '.jpg';
};

const SiteIcon: FC<Props> = ({ icon, ext, ...imgProps }) => {
  const { iconSetCdnPath, iconSetExt } = useSiteConfig();
  const extension = ext ?? iconSetExt;
  const src = icon.startsWith('http')
    ? icon
    : `${iconSetCdnPath}/${icon}${extension}`;
  return <Img alt={icon} {...imgProps} src={src} preload={false} />;
};

export default SiteIcon;
