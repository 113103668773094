import type { ThemeFragment } from '~/shared/graphql/home/ThemesQuery.generated';
import useThemeProps from './helpers/useThemeProps';
import ThemeCard from './ThemeCard';

type Props = { theme: ThemeFragment; index: number };

const Theme: FC<Props> = ({ theme, index }) => {
  const { color, bgColor, bgColorHover } = useThemeProps(theme);
  return (
    <ThemeCard
      handle={theme.handle}
      color={color}
      bgColor={bgColor}
      bgColorHover={bgColorHover}
      index={index}
    />
  );
};

export default Theme;
