import { mergeClassnames } from '@heathmont/moon-core-tw';
import type { PromotionFragment } from '~/client/components/Promotions/Promotion/graphql/GetPromotionQuery.generated';
import useLanguage from '~/client/hooks/useLanguage';
import BannerNavigationItem from './BannerNavigationItem';

type Props = {
  promotions: PromotionFragment[];
  step: number;
  delay: number;
  onChanged: (nextStep: number) => void;
  maxBannersInSlider?: number;
};

const BannerNavigation: FC<Props> = ({
  promotions,
  step,
  delay,
  onChanged,
  maxBannersInSlider,
}) => {
  const { locale } = useLanguage();
  const borderRadius =
    locale === 'ar'
      ? '3xl:rounded-r-none 3xl:rounded-l-moon-i-md'
      : '3xl:rounded-r-moon-s-md';

  return (
    <div
      className={mergeClassnames(
        'ml-0 hidden max-w-none grid-flow-col auto-rows-min grid-cols-4 overflow-hidden rounded-b-moon-i-md bg-gohan',
        'lg:grid 3xl:-ml-3 3xl:grid-flow-row 3xl:grid-cols-none 3xl:pl-3 3xl:pt-1 3xl:rem:max-w-[268px]',
        maxBannersInSlider === 5 && 'grid-cols-5',
        borderRadius,
      )}
    >
      {promotions.map((promotion, i) => (
        <BannerNavigationItem
          isActive={i === step}
          isLast={i === promotions.length - 1}
          delay={delay}
          key={promotion.id}
          promotion={promotion}
          onClick={() => onChanged(i)}
        />
      ))}
    </div>
  );
};

export default BannerNavigation;
