import { Carousel, Loader } from '@heathmont/moon-core-tw';
import useAgentData from '~/client/hooks/useAgentData';
import BannerCarousel from './BannerCarousel';
import useBannerCarousel from './BannerCarousel/hooks/useBannerCarousel';
import BannerNavigation from './BannerNavigation';
import PromoBanner from './PromoBanner';
import useLoggedInBanner from './PromoBanner/hooks/useLoggedInBanner';

const DELAY = 9000;

const LoggedInBanner = () => {
  const { isMobile, isDesktop } = useAgentData();
  const { data, loading, maxBannersInSlider } = useLoggedInBanner();

  const items = data?.map((promotion, index) => {
    const preload = index === 0;
    return (
      <PromoBanner
        key={promotion?.id}
        promotion={promotion}
        preload={preload}
        index={index}
      />
    );
  });

  const { step, goToStep } = useBannerCarousel({
    totalStep: items?.length ?? 0,
    delay: isMobile ? 0 : DELAY,
  });

  if (loading) {
    return (
      <div className="grid place-items-center gap-4">
        <Loader />
      </div>
    );
  }

  if (items === undefined || data === undefined) {
    return null;
  }

  if (!isDesktop) {
    return (
      <div data-testid="mobile-loggedin-banner" className="-mx-5">
        <Carousel step={1} scrollTo={step}>
          <Carousel.Reel className="gap-3 px-5 scroll-hidden">
            {items.map((item) => (
              <Carousel.Item
                key={item.key}
                className="w-full rounded-moon-s-md"
              >
                {item}
              </Carousel.Item>
            ))}
          </Carousel.Reel>
          <Carousel.Indicators className="relative bottom-0 mt-4 justify-center" />
        </Carousel>
      </div>
    );
  }

  return (
    <div
      className="grid grid-flow-row grid-rows-[auto_102px] 3xl:grid-cols-[calc(100%-16rem)_16rem] 3xl:grid-rows-none"
      data-testid="desktop-loggedin-banner"
    >
      <BannerCarousel
        items={items ?? []}
        step={1}
        scrollTo={step}
        itemClassName="bg-transparent"
      />
      <BannerNavigation
        step={step}
        delay={DELAY}
        promotions={data}
        onChanged={(nextStep) => goToStep(nextStep)}
        maxBannersInSlider={maxBannersInSlider}
      />
    </div>
  );
};

export default LoggedInBanner;
