import useAgentData from '~/client/hooks/useAgentData';
import isSsr from '~/shared/utils/isSsr';
import useLoggedInBannerRequest from './useLoggedInBannerRequest';
import useFirstDepositBannerRequest from '../FirstDepositBanner/hooks/useFirstDepositBannerRequest';
import useShouldShowFirstDepositBanner from '../FirstDepositBanner/hooks/useShouldShowFirstDepositBanner';

const useLoggedInBanner = () => {
  const { isMobile } = useAgentData();
  const { data, loading } = useLoggedInBannerRequest();
  const showFirstDepositBanner = useShouldShowFirstDepositBanner();
  const firstDepositBannerData = useFirstDepositBannerRequest();

  const isFirstDepositBanner =
    firstDepositBannerData !== undefined && showFirstDepositBanner;

  const maxBannersInSlider =
    !isSsr() && (window.innerWidth >= 1500 || isMobile) ? 5 : 4;

  const promotions = data?.content?.currentPromotions?.edges?.reduce(
    (acc, { node }) => {
      if (node !== undefined && acc.length < maxBannersInSlider) {
        acc.push(node);
      }

      return acc;
    },
    isFirstDepositBanner ? [firstDepositBannerData] : [],
  );
  return { loading, data: promotions, maxBannersInSlider };
};

export default useLoggedInBanner;
