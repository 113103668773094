import dynamic from 'next/dynamic';
import useFeature from '~/client/hooks/feature/useFeature';

const PrizePromoComponent = dynamic(() => import('./PrizePromoComponent'), {
  ssr: false,
});

const PrizePromo = () => {
  const isPrizePromoEnabled = useFeature('prizePromo');

  if (!isPrizePromoEnabled) {
    return null;
  }

  return <PrizePromoComponent />;
};

export default PrizePromo;
