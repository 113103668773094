import type { LoggedInBannerQueryVariables } from '../LoggedInBannerQuery.generated';

const getLoggedInQueryVariables = (
  site: Site,
  language: string,
): LoggedInBannerQueryVariables => ({
  first: 5,
  filter: {
    site,
    language,
    section: 'promotions-main',
    casinoBanner: true,
  },
});

export default getLoggedInQueryVariables;
