import { mergeClassnames } from '@heathmont/moon-core-tw';
import useAgentData from '~/client/hooks/useAgentData';
import useSite from '~/client/hooks/useSite';
import Img from '~/client/shared/images/Img';
import applyImgixToUrl from '~/client/shared/images/imgix/applyImgixToUrl';
import TailwindThemeProvider from '~/client/shared/ThemeProviders/TailwindThemeProvider';
import getCdnImg from '~/shared/cdn/getCdnImg';

const DefaultImg =
  '//heathmont.imgix.net/bitcasino/images/characters/chubby-miner.png?auto=compress,format';

const getBannerImgWidth = (isMobile: boolean, isBitcasino: boolean): number => {
  if (isBitcasino) {
    return isMobile ? 272 : 720;
  }
  return isMobile ? 640 : 1320;
};

const getBannerImgHeight = (
  isMobile: boolean,
  isBitcasino: boolean,
): number => {
  if (isBitcasino) {
    return isMobile ? 196 : 516;
  }
  return isMobile ? 488 : 868;
};

const getDefaultBannerImg = (width: number, height: number): string =>
  `${DefaultImg}&w=${width}&h=${height}`;

type Props = {
  src: string;
  preload?: boolean;
};

const BannerImg: FC<Props> = ({ src, preload = false }) => {
  const { isSportsbet, isEmpire, isBitcasino, site } = useSite();
  const { isMobile } = useAgentData();
  const bannerImgWidth = getBannerImgWidth(isMobile, isBitcasino);
  const bannerImgHeight = getBannerImgHeight(isMobile, isBitcasino);
  const bannerImg =
    applyImgixToUrl({
      src,
      w: bannerImgWidth,
      h: bannerImgHeight,
    }) || getDefaultBannerImg(bannerImgWidth, bannerImgHeight);
  const hasBgPattern = isSportsbet || isEmpire;

  return (
    <TailwindThemeProvider colorScheme="dark" className="h-full md:h-auto">
      <div
        className={mergeClassnames(
          'h-full bg-gohan md:bg-transparent',
          isBitcasino && 'bg-transparent md:h-auto',
        )}
      >
        <Img
          className={mergeClassnames(
            'h-60 w-full rounded-moon-s-md bg-goku/50 object-cover',
            'bg-goku/moon md:static md:bottom-0 md:left-[initial] md:right-0 md:top-[initial] md:h-full md:transform-none md:bg-transparent md:object-[initial]',
            'landscape:height2xs:h-[18.7rem] landscape:height2xs:object-none',
            hasBgPattern && 'bg-gohan',
            hasBgPattern &&
              isMobile &&
              'bg-cover bg-center bg-no-repeat bg-blend-soft-light',
            isBitcasino &&
              'bg-[#E9D7D3] object-none rem:h-[196px] md:h-auto md:object-contain',
          )}
          style={{
            backgroundImage:
              hasBgPattern &&
              isMobile &&
              `url(${getCdnImg(`${site}_pattern`, '.svg')})`,
          }}
          preload={preload}
          src={bannerImg}
          width={bannerImgWidth}
          height={bannerImgHeight}
        />
      </div>
    </TailwindThemeProvider>
  );
};

export default BannerImg;
