import Lazy from '~/client/shared/LazyLoad/Lazy';
import CarouselPlaceholder from '~/client/shared/LazyLoad/Placeholder/CaroulselPlaceholder';
import GameProvidersView from './GameProvidersView';

const placeholder = <CarouselPlaceholder width={152} height={80} />;

const GameProviders: FC = () => (
  <Lazy placeholder={placeholder}>
    <GameProvidersView placeholder={placeholder} />
  </Lazy>
);

export default GameProviders;
