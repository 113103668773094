const VideoContainer: FC = ({ children }) => (
  <div
    className={
      'relative inset-0 h-60 overflow-hidden rounded-moon-s-md bg-goku after:absolute after:top-0 after:h-full after:w-full after:bg-[url(https://cdn.coingaming.io/promotions/2021/mask-dot.png)] md:absolute md:-inset-x-10 md:-bottom-10 md:h-[initial] md:w-[initial] [&_video]:relative md:[&_video]:right-0'
    }
  >
    {children}
  </div>
);

export default VideoContainer;
