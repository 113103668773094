import { mergeClassnames } from '@heathmont/moon-core-tw';
import useSite from '~/client/hooks/useSite';

const ImageContainer: FC = ({ children }) => {
  const { isBitcasino } = useSite();
  return (
    <div
      className={mergeClassnames(
        'w-full self-end overflow-hidden rounded-moon-s-md rtl:-scale-x-100 md:h-auto landscape:height2xs:h-72',
        !isBitcasino &&
          'h-60 md:absolute md:-bottom-5 md:left-[40%] md:right-[unset] md:h-[27rem] md:w-[41rem] rtl:md:left-[unset] rtl:md:right-[40%] lg:left-[35%] lg:right-[unset] rtl:lg:left-[unset] rtl:lg:right-[35%] xl:left-[40%] xl:right-[unset] rtl:xl:left-[unset] rtl:xl:right-[40%] 2xl:left-[45%] 2xl:right-[unset] rtl:2xl:left-[unset] rtl:2xl:right-[45%]',
      )}
    >
      {children}
    </div>
  );
};

export default ImageContainer;
