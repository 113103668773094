import useSite from '~/client/hooks/useSite';
import getThemesVariables from '~/shared/graphql/home/helpers/getThemesVariables';
import { useThemesQuery } from '~/shared/graphql/home/ThemesQuery.generated';

const useThemes = () => {
  const { site } = useSite();
  const variables = getThemesVariables(site);
  const { data, loading } = useThemesQuery({ variables });
  const themes = data?.casino?.themes?.edges ?? [];
  return { themes, loading };
};

export default useThemes;
