import useAgentData from '~/client/hooks/useAgentData';
import NextLink from '~/client/shared/NextLink';
import Card from './Card';
import { PromotionFragment } from '@/shared/graphql/PromotionFragment.generated';

type Props = {
  promotion?: PromotionFragment;
  preload?: boolean;
};

const PandaBanner = ({ preload, promotion }: Props) => {
  const { link, handle = '' } = promotion ?? {};
  const { isMobile } = useAgentData();

  return (
    <NextLink href={link || `/promotions/${handle}`} data-testid="panda-banner">
      <Card promotion={promotion} preload={preload} isMobile={isMobile} />
    </NextLink>
  );
};

export default PandaBanner;
