import BlogNewsPlaceholder from '~/client/components/Home/BlogNews/BlogNewsPlaceholder';
import useFeature from '~/client/hooks/feature/useFeature';
import Lazy from '~/client/shared/LazyLoad/Lazy';
import BlogNewsView from './BlogNewsView';

const placeholder = <BlogNewsPlaceholder />;

const BlogNews: FC = () => {
  const isBlogNewsFlagActive = useFeature('blogNews');
  if (!isBlogNewsFlagActive) {
    return null;
  }
  return (
    <Lazy placeholder={placeholder}>
      <BlogNewsView placeholder={placeholder} />
    </Lazy>
  );
};

export default BlogNews;
