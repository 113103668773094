import { mergeClassnames } from '@heathmont/moon-core-tw';
import type bannerType from '../../../../types/bannerType';

type Props = {
  color: string;
  bgColor: string;
  bannerType: bannerType;
};

const Container: FC<Props> = ({ children, bannerType, color, bgColor }) => (
  <div
    className={mergeClassnames(
      'mb-[2px] flex h-10 min-h-[2rem] min-w-[2.25rem] items-center justify-center rounded-moon-s-sm border border-solid leading-7 xl:max-h-10',
      bannerType === 'gameBanner' || bannerType === 'secondary'
        ? 'text-moon-20'
        : 'text-moon-14',
      bannerType === 'gameBanner' && 'font-medium',
      color && color,
      bannerType !== 'secondary' && `${bgColor} border-beerus`,
      bannerType === 'secondary' && ' min-w-[3rem] border-0',
    )}
  >
    {children}
  </div>
);

export default Container;
