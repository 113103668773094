import type { LoggedOutBannerQueryVariables } from '../LoggedOutBannerQuery.generated';

const getLoggedOutQueryVariables = (
  site: Site,
  language: string,
  bannerHandle?: 'bcio-main-banner-logged-out' | 'main-banner-logged-out',
): LoggedOutBannerQueryVariables => ({
  filter: {
    site,
    language,
    handle: bannerHandle,
  },
});

export default getLoggedOutQueryVariables;
