import { mergeClassnames } from '@heathmont/moon-core-tw';
import type { PromotionFragment } from '~/client/components/Promotions/Promotion/graphql/GetPromotionQuery.generated';
import Img from '~/client/shared/images/Img';
import TailwindThemeProvider from '~/client/shared/ThemeProviders/TailwindThemeProvider';
import useBannerNavigationItem from './hooks/useBannerNavigationItem';
import ProgressBar from './ProgressBar';

type Props = {
  promotion: PromotionFragment;
  isActive: boolean;
  isLast: boolean;
  delay: number;
  onClick: () => void;
};

const BannerNavigationItem: FC<Props> = ({
  promotion,
  isActive,
  isLast,
  delay,
  onClick,
}) => {
  const { showProgress, imgSrc, imgHeight, isBitcasino } =
    useBannerNavigationItem(promotion, isActive);
  return (
    <div
      className="relative grid cursor-pointer grid-cols-[1fr_2fr] items-center gap-4 px-6 rem:min-h-[102px]"
      onClick={onClick}
    >
      <TailwindThemeProvider colorScheme="dark">
        <Img
          src={imgSrc}
          height={imgHeight}
          lazy={false}
          alt=""
          className={mergeClassnames(
            'rounded-moon-s-xs bg-gohan object-cover rem:h-[54px] rem:w-[72px]',
            isBitcasino && 'bg-popo',
          )}
        />
      </TailwindThemeProvider>
      <div
        className={mergeClassnames(
          'line-clamp-3 block max-h-16 break-words text-sm font-medium',
          isBitcasino && 'font-headline font-light',
        )}
      >
        {promotion.name}
      </div>
      {(!isLast || isActive) && (
        <div
          className={mergeClassnames(
            'absolute left-0 hidden w-full bg-beerus rem:bottom-[0.5px] rem:h-[0.5px] 3xl:block',
            isActive && 'h-0.5',
          )}
        />
      )}
      {isActive && <ProgressBar isActive={showProgress} delay={delay} />}
      {!isLast && (
        <div className="absolute right-0 h-14 w-px bg-beerus 3xl:hidden" />
      )}
    </div>
  );
};

export default BannerNavigationItem;
