import { mergeClassnames } from '@heathmont/moon-core-tw';
import useSite from '~/client/hooks/useSite';

const NoCryptoBannerContainer: FC = ({ children }) => {
  const { isLivecasino } = useSite();

  return (
    <div
      className={mergeClassnames(
        'inline-flex gap-4 lg:w-full',
        isLivecasino && '-mx-7 p-7',
      )}
    >
      {children}
    </div>
  );
};

export default NoCryptoBannerContainer;
