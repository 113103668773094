import useGameCardSize from '~/client/shared/game/GameCard/useGameCardSize';
import GameCarousel from '~/client/shared/game/GameCarousel';
import Lazy from '~/client/shared/LazyLoad/Lazy';
import CarouselPlaceholder from '~/client/shared/LazyLoad/Placeholder/CaroulselPlaceholder';

const BitcasinoOriginals = () => {
  const { width, height } = useGameCardSize();
  const titleId = 'casino.theme.bitcasino-originals';
  const href = '/themes/bitcasino-originals';
  const placeholder = (
    <CarouselPlaceholder
      width={width}
      height={height}
      titleId={titleId}
      href={href}
    />
  );
  return (
    <Lazy placeholder={placeholder}>
      <GameCarousel
        sliderName="bitcasino_originals"
        sourcePage="front_page"
        big
        titleId={titleId}
        href={href}
        theme="bitcasino-originals"
        placeholder={placeholder}
      />
    </Lazy>
  );
};

export default BitcasinoOriginals;
