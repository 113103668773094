import { mergeClassnames } from '@heathmont/moon-core-tw';
import { ArrowsRight } from '@heathmont/moon-icons-tw';
import BannerContainer from '~/client/components/Home/Banner/shared/BannerContainer';
import useSite from '~/client/hooks/useSite';
import ImageContainer from '../../../../shared/ImageContainer';
import MainBannerContainer from '../../../../shared/MainBannerContainer';
import VideoContainer from '../../../../shared/VideoContainer';
import BannerBackground from '../../BannerBackground';
import BannerImg from '../../BannerImg';
import BannerVideo from '../../BannerVideo';
import ClaimWelcomeBonus from '../../ClaimWelcomeBonus';
import PromoButtonLink from '../../PromoButtonLink';
import type Props from '../../Props';
import usePromoBanner from '../../usePromoBanner';

const Content: FC<Props> = ({ promotion, preload, index, borderRadiuses }) => {
  const {
    handle = '',
    title,
    summary,
    largeImage = '',
    mediumImage = '',
    link,
    bannerButtonText,
  } = promotion ?? {};
  const { isBitcasino } = useSite();
  const { handleClick, showClaimReward, image, video, isMobile } =
    usePromoBanner({
      handle,
      largeImage,
      mediumImage,
    });
  return (
    <BannerContainer borderRadiuses={borderRadiuses}>
      <MainBannerContainer>
        {video && (
          <VideoContainer>
            <BannerVideo img={image} video={video} />
          </VideoContainer>
        )}
        {!video && (
          <ImageContainer>
            <BannerImg src={image} preload={preload} />
          </ImageContainer>
        )}
        {isMobile && (
          <ArrowsRight className="absolute right-3 top-3 justify-self-start text-moon-32 text-goten" />
        )}
        <div className="relative z-1 flex h-full max-w-xl flex-col justify-end gap-4 self-center p-4 md:row-span-full md:content-center md:justify-center xl:max-w-md xl:gap-6 xl:p-0 rtl:xl:p-4 landscape:height2xs:max-w-none">
          <h1
            className={mergeClassnames(
              'line-clamp-3 break-words text-moon-18 font-semibold leading-7 text-bulma md:text-moon-32 md:leading-tight',
              isBitcasino && 'font-headline font-light md:text-popo',
            )}
          >
            {title}
          </h1>
          <h3
            className={mergeClassnames(
              'hidden w-full overflow-hidden break-words text-moon-20 font-normal text-bulma md:line-clamp-2',
              isBitcasino && 'text-popo',
            )}
          >
            {summary}
          </h3>
          <div className="grid grid-flow-col gap-2">
            <PromoButtonLink
              link={link}
              handleClick={handleClick}
              handle={handle}
              text={bannerButtonText}
            />
            <ClaimWelcomeBonus showClaimReward={showClaimReward} />
          </div>
        </div>
      </MainBannerContainer>
      <BannerBackground index={index} />
    </BannerContainer>
  );
};

export default Content;
