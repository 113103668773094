import type TimerOptions from './TimerOptions';
import TimerView from './TimerView';
import type bannerType from './TimerView/types/bannerType';
import useTimer from './useTimer';

type Props = TimerOptions & {
  lowCaseTitle?: boolean;
  color?: string;
  bgColor?: string;
  bannerType?: bannerType;
};

const Timer: FC<Props> = ({
  color,
  bgColor,
  lowCaseTitle,
  bannerType = 'default',
  ...options
}) => {
  const timeLeft = useTimer(options);
  return (
    <TimerView
      timeLeft={timeLeft}
      color={color}
      bgColor={bgColor}
      lowCaseTitle={lowCaseTitle}
      bannerType={bannerType}
      format={options.format}
    />
  );
};

export default Timer;
