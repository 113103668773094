import useFeature from '~/client/hooks/feature/useFeature';
import useGameCardSize from '~/client/shared/game/GameCard/useGameCardSize';
import Lazy from '~/client/shared/LazyLoad/Lazy';
import CarouselPlaceholder from '~/client/shared/LazyLoad/Placeholder/CaroulselPlaceholder';
import RecommendedGamesCarousel from './RecommendedGamesCarousel';
import useRecommendedGamesCarousel from './RecommendedGamesCarousel/hooks/useRecommendedGamesCarousel';

const TopPicksForYou: FC = () => {
  const { width, height } = useGameCardSize();
  const { titleId, href } = useRecommendedGamesCarousel();

  const isRecommendedGamesEnabled = useFeature('isRecommendedGamesEnabled');

  if (!isRecommendedGamesEnabled) {
    return null;
  }
  const placeholder = (
    <CarouselPlaceholder
      width={width}
      height={height}
      titleId={titleId}
      href={href}
    />
  );
  return (
    <div data-testid="recommended-games">
      <Lazy placeholder={placeholder}>
        <RecommendedGamesCarousel placeholder={placeholder} />
      </Lazy>
    </div>
  );
};

export default TopPicksForYou;
