import dynamic from 'next/dynamic';
import useFeature from '~/client/hooks/feature/useFeature';

const RegistrationReminderPopup = dynamic(
  () => import('./RegistrationReminderPopup'),
  {
    ssr: false,
  },
);

const RegistrationReminder = () => {
  const registrationReminderEnabled = useFeature('registrationReminder');

  if (!registrationReminderEnabled) {
    return null;
  }

  return <RegistrationReminderPopup />;
};

export default RegistrationReminder;
