import { createEvent, createStore } from 'effector';
import { useUnit } from 'effector-react';
import useAgentData from '~/client/hooks/useAgentData';
import sendInteractionToGtm from '~/shared/gtm/sendInteractionToGtm';

type SidebarStore = {
  isOpen?: boolean;
};

const sidebarStore = createStore<SidebarStore>({});
const setOpen = createEvent<boolean>();
sidebarStore.on(setOpen, (store, isOpen) => {
  sendInteractionToGtm({
    eventAction: 'Sidebar_menu',
    eventCategory: 'arrow_button',
    eventInfo: isOpen ? 'collapse' : 'expand',
  });
  return { ...store, isOpen };
});

const useSidebarStore = () => {
  const store = useUnit(sidebarStore);
  const { isDesktop, isCrawler } = useAgentData();
  const isOpen = isDesktop || isCrawler;
  return { setOpen, isOpen, ...store };
};

export default useSidebarStore;
