import Img from '~/client/shared/images/Img';
import { CDN_IMG_URL } from '~/shared/cdn/url';
import useNoCryptoBannerLoggedIn from './hooks/useNoCryptoBannerLoggedIn';
import NoCryptoBannerCard from '../NoCryptoBannerCard';
import NoCryptoBannerDescription from '../NoCryptoBannerCard/NoCryptoBannerDescription';
import NoCryptoBannerTitle from '../NoCryptoBannerCard/NoCryptoBannerTitle';

const NoCryptoBannerLoggedIn: FC = () => {
  const { onClickFiatBanner, imgSrc, onClickCryptoBanner } =
    useNoCryptoBannerLoggedIn();

  const banners = [
    {
      id: 'fiat',
      bg: 'from-[#77213b] to-[#19051d]',
      title: 'no-crypto-banner.no-funds-no-fun-title',
      description: 'no-crypto-banner.no-funds-no-fun-description',
      callback: onClickFiatBanner,
    },
    {
      id: 'crypto',
      bg: 'from-[#053936] to-[#000000]',
      title: 'no-crypto-banner.play-without-delay-title',
      description: 'no-crypto-banner.play-without-delay-description',
      callback: onClickCryptoBanner,
    },
  ];

  return (
    <>
      {banners.map(({ id, bg, title, description, callback }) => (
        <NoCryptoBannerCard key={id} onBannerClick={callback} classNames={bg}>
          <Img
            className="mb-3 xl:mb-0 xl:max-w-[10rem] 3xl:max-w-[14rem]"
            src={`${CDN_IMG_URL}/banners/${id}-${imgSrc}.png`}
          />
          <div className="flex flex-col gap-2 xl:ml-4 3xl:ml-8 3xl:flex-row">
            <NoCryptoBannerTitle titleId={title} />
            <NoCryptoBannerDescription descriptionId={description} />
          </div>
        </NoCryptoBannerCard>
      ))}
    </>
  );
};

export default NoCryptoBannerLoggedIn;
