import CustomCarousel from '~/client/shared/Carousel/CustomCarousel';
import PageSection from '~/client/shared/PageSection';
import isListEmpty from '~/shared/utils/isListEmpty';
import BlogNewsCard from './BlogNewsCard';
import useBitcasinoNews from './useBitcasinoNews';

type Props = { placeholder: JSX.Element };

const BlogNewsView: FC<Props> = ({ placeholder }) => {
  const { articles, loading } = useBitcasinoNews();

  if (loading) {
    return <>{placeholder}</>;
  }
  if (isListEmpty(articles)) {
    return null;
  }
  const items = articles.map((article) => {
    if (!article?.node) {
      return null;
    }
    return <BlogNewsCard key={article.node.id} article={article.node} />;
  });
  return (
    <PageSection titleId="casino.bitcasino_news_site" href="/blog">
      <CustomCarousel items={items ?? []} />
    </PageSection>
  );
};

export default BlogNewsView;
