import getCurrency from './getCurrency';
import isUnit from './isUnit';

const removeUnit = (
  amount: number,
  from: string,
  convertUnit: (
    value: number | string,
    currency: string,
    fromUnit: string,
    toUnit: string,
  ) => number,
) => {
  if (!isUnit(from)) {
    return amount;
  }
  const currency = getCurrency(from);
  return convertUnit(amount, currency, from, currency);
};

export default removeUnit;
