import { useEffect, useState } from 'react';
import type { PromotionFragment } from '~/client/components/Promotions/Promotion/graphql/GetPromotionQuery.generated';
import useSite from '~/client/hooks/useSite';
import getImgixSrc from '~/client/shared/images/imgix/getImgixSrc';

const WIDTH = 144;
const HEIGHT = 60;
const DEFAULT_IMAGE =
  '//heathmont.imgix.net/bitcasino/images/characters/chubby-miner.png?auto=compress,format';

const useBannerNavigationItem = (
  promotion: PromotionFragment,
  isActive: boolean,
) => {
  const { isBitcasino } = useSite();
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    setShowProgress(isActive);
  }, [isActive]);

  const imgixProps = {
    src: promotion.smallImage
      ? promotion.smallImage.split('|')[0]
      : DEFAULT_IMAGE,
    width: WIDTH,
    height: HEIGHT,
    alt: '',
  };
  const imgSrc = getImgixSrc(imgixProps);

  return { showProgress, imgSrc, imgHeight: HEIGHT, isBitcasino };
};

export default useBannerNavigationItem;
