import Lazy from '~/client/shared/LazyLoad/Lazy';
import LazyBestGameOfTheWeek from './BestGameOfTheWeek';
import BestGameOfTheWeekPlaceholder from './BestGameOfTheWeekPlaceholder';

const placeholder = <BestGameOfTheWeekPlaceholder />;

const BestGameOfTheWeek: FC = () => (
  <Lazy placeholder={placeholder}>
    <LazyBestGameOfTheWeek placeholder={placeholder} />
  </Lazy>
);

export default BestGameOfTheWeek;
