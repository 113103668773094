import useFeature from '~/client/hooks/feature/useFeature';
import NoCryptoBannerView from './NoCryptoBannerView';

const NoCryptoBanner = () => {
  const isNoCryptoBannerVisible = useFeature('isNoCryptoBannerVisible');

  if (!isNoCryptoBannerVisible) {
    return null;
  }

  return <NoCryptoBannerView />;
};

export default NoCryptoBanner;
