import useLanguage from '~/client/hooks/useLanguage';
import useSite from '~/client/hooks/useSite';
import getTestimonialsVariables from '~/shared/graphql/home/helpers/getTestimonialsVariables';
import { useTestimonialsQuery } from '~/shared/graphql/home/TestimonialsQuery.generated';

const SEPARATOR = '<!-- separator -->';

const useTestimonials = () => {
  const { site } = useSite();
  const { code } = useLanguage();
  const variables = getTestimonialsVariables(site, code);
  const { data, loading } = useTestimonialsQuery({ variables });
  const mainBlock = data?.content?.pages?.edges?.[0]?.node?.mainBlock;
  const testimonials = mapTestimonials(mainBlock);
  return { testimonials, loading };
};

const mapTestimonials = (mainBlock = '') =>
  mainBlock.split(SEPARATOR).reduce((accumulated, current) => {
    const testimonial = current.trim();
    if (!testimonial.length || !testimonial.startsWith('<div')) {
      return accumulated;
    }
    return [...accumulated, testimonial];
  }, [] as string[]);

export default useTestimonials;
