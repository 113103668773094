import { useEffect, useState } from 'react';
import { mergeClassnames } from '@heathmont/moon-core-tw';

type Props = {
  isActive: boolean;
  delay: number;
};

const ProgressBar: FC<Props> = ({ isActive, delay }) => {
  const [animation, start] = useState(false);
  useEffect(() => {
    start(isActive);
  }, [isActive]);

  return (
    <div
      style={{ transitionDuration: `${delay}ms` }}
      className={mergeClassnames(
        'absolute left-0 top-0 h-0.5 w-full bg-piccolo 3xl:bottom-0 3xl:top-auto',
        'origin-top-left transition ease-linear ltr:[transform:scale3d(0,1,1)] rtl:[transform:scale3d(1,1,1)]',
        animation &&
          'ltr:[transform:scale3d(1,1,1)] rtl:[transform:scale3d(0,1,1)]',
      )}
    />
  );
};

export default ProgressBar;
