import useLanguage from '~/client/hooks/useLanguage';
import useSite from '~/client/hooks/useSite';
import getLoggedInQueryVariables from '~/shared/graphql/home/helpers/getLoggedInQueryVariables';
import { useLoggedInBannerQuery } from '~/shared/graphql/home/LoggedInBannerQuery.generated';

const useLoggedInBannerRequest = () => {
  const { site } = useSite();
  const { code } = useLanguage();
  const variables = getLoggedInQueryVariables(site, code);
  const { loading, data } = useLoggedInBannerQuery({
    variables,
    fetchPolicy: 'network-only',
  });

  return { data, loading, site };
};

export default useLoggedInBannerRequest;
