import useLanguage from '~/client/hooks/useLanguage';
import useSite from '~/client/hooks/useSite';
import {
  useBestGameOfTheWeekQuery,
  useHighlightedGameQuery,
} from './BestGameOfTheWeekQuery.generated';

const useBestGameOfTheWeek = () => {
  const { site, isEmpire } = useSite();
  const { gameHandle, img, bgColor, title } = useArticleData(site, isEmpire);
  const { href, name, provider, loading } = useGameData(site, gameHandle);
  return { img, bgColor, title, href, name, provider, loading };
};

const useArticleData = (site: Site, isEmpire: boolean) => {
  const section = isEmpire ? 'site-blocks-new' : 'site-blocks';
  const handle = 'highlighted-game';
  const { code: language } = useLanguage();
  const { data } = useBestGameOfTheWeekQuery({
    variables: { filter: { site, language, section, handle } },
  });
  const { gameHandle, img, bgColor, title } =
    data?.content?.pages?.edges?.[0]?.node ?? {};
  return { gameHandle, img, bgColor, title };
};

const useGameData = (site: Site, handle?: string) => {
  const { data, loading } = useHighlightedGameQuery({
    variables: { filter: { site, handle } },
    skip: !handle,
  });
  const { href, name, provider } = data?.casino?.games?.edges?.[0]?.node ?? {};
  return { href, name, provider, loading };
};

export default useBestGameOfTheWeek;
