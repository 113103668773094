import { convertUnit } from '@heathmont/money';
import addUnit from '~/client/hooks/currency/helpers/addUnit';
import removeUnit from '~/client/hooks/currency/helpers/removeUnit';

const convert = (amount: number, from: string, to: string, rate: number) => {
  try {
    const rawAmount = removeUnit(amount, from, convertUnit);
    const converted = applyRate(rawAmount, rate);
    return addUnit(converted, to, convertUnit);
  } catch (_) {
    return undefined;
  }
};

const applyRate = (amount: number, rate: number) => amount * rate;

export default convert;
