import { mergeClassnames } from '@heathmont/moon-core-tw';
import useFeature from '~/client/hooks/feature/useFeature';
import useAgentData from '~/client/hooks/useAgentData';
import Translate from '~/client/shared/i18n/Translate';
import BannerContentContainer from './BcIoLoggedOutBannerCard/BannerContentContainer';
import styles from './styles.module.scss';
import useLoggedOutBanner from './useLoggedOutBanner';

const LoggedOutBanner: FC = () => {
  const { content, site, targetRef, handleClick } = useLoggedOutBanner();
  const { isMobile } = useAgentData();
  const isBcIoLoggedOutBannerEnabled = useFeature(
    'isBcIoLoggedOutBannerEnabled',
  );
  if (content.toString().length === 0) {
    return null;
  }

  if (isBcIoLoggedOutBannerEnabled) {
    return (
      <div className="text-center">
        {isMobile && (
          <Translate
            id="bcio.mobile_banner_title"
            className="font-fraunces text-moon-24 font-normal text-bulma md:text-moon-32 xl:text-[1.75rem] xl:leading-8 2xl:text-[2.5rem] 2xl:leading-10 3xl:text-moon-48"
          />
        )}
        <div
          ref={targetRef}
          onClick={handleClick}
          className={mergeClassnames(
            styles.bannerLogoutContainer,
            site !== 'sportsbet' && 'md:bg-transparent',
          )}
        >
          <BannerContentContainer content={content} isMobile={isMobile} />
        </div>
      </div>
    );
  }

  return (
    <div
      ref={targetRef}
      onClick={handleClick}
      className={mergeClassnames(
        styles.bannerLogoutContainer,
        site !== 'sportsbet' && 'md:bg-transparent',
      )}
    >
      {content}
    </div>
  );
};

export default LoggedOutBanner;
