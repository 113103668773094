import { mergeClassnames } from '@heathmont/moon-core-tw';
import Translate from '~/client/shared/i18n/Translate';
import Img from '~/client/shared/images/Img';
import BcIoCdn from './BcIoCdn';

type Props = { isMobile?: boolean };

const values = [
  { icon: BcIoCdn.lightning, body: 'bcio.withdrawals' },
  { icon: BcIoCdn.shield, body: 'bcio.limits' },
  { icon: BcIoCdn.crown, body: 'bcio.jackpot' },
];

const BcIoLoggedOutBannerCard: FC<Props> = ({ isMobile }) => (
  <div
    className={mergeClassnames(
      'grid gap-4 relative pt-4 h-20 w-full',
      isMobile && 'overflow-auto',
    )}
  >
    <div className={'grid grid-flow-col gap-4'}>
      {values.map(({ icon, body }) => (
        <div
          className={mergeClassnames(
            'flex items-center rounded-moon-s-lg border border-beerus',
            isMobile && 'w-[316px]',
          )}
          key={icon}
        >
          <Img className="w-10 mx-2 md:w-14" src={icon} />
          <Translate
            className="font-fraunces mx-2 text-moon-14 font-light relative md:text-moon-18"
            id={body}
          />
        </div>
      ))}
    </div>
  </div>
);

export default BcIoLoggedOutBannerCard;
