import useSite from '~/client/hooks/useSite';
import { useGameCategoriesQuery } from '~/shared/graphql/home/GameCategoriesQuery.generated';
import getGameCategoriesVariables from '~/shared/graphql/home/helpers/getGameCategoriesVariables';

const useGameCategories = () => {
  const { site } = useSite();
  const variables = getGameCategoriesVariables(site);
  const { data, loading } = useGameCategoriesQuery({ variables });
  const categories = data?.casino?.gameCategories?.edges ?? [];
  return { categories, loading };
};

export default useGameCategories;
