import Img from '~/client/shared/images/Img';
import getCdnIcon from '~/shared/cdn/getCdnIcon';

const NoCryptoBannerVideoThumbnail: FC = () => (
  <div className="relative z-0 mb-3 flex h-2/3 w-full items-center justify-center rounded-moon-s-sm border-4 border-gohan bg-gohan xl:mb-0 xl:h-20 xl:w-[154px] xl:flex-shrink-0 3xl:h-28 3xl:w-56">
    <div className="absolute left-0 top-0 -z-1 h-full w-full  rounded-moon-s-sm bg-[#E9D7D3]">
      {''}
    </div>

    <div className="flex h-12 w-12 justify-center place-self-center rounded-full bg-bulma">
      <Img
        width={20}
        height={20}
        alt="play game"
        src={getCdnIcon('play-triangle')}
      />
    </div>
  </div>
);

export default NoCryptoBannerVideoThumbnail;
