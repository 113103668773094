import { memo } from 'react';
import type BoxType from './BoxType';
import Field from './Field';
import Separator from './Separator';
import type TimerFormat from '../../TimerFormat';
import type bannerType from '../types/bannerType';

type Props = {
  type: BoxType;
  value?: number;
  color?: string;
  bgColor?: string;
  lowCaseTitle?: boolean;
  bannerType: bannerType;
  format: TimerFormat;
};

const Box: FC<Props> = ({
  type,
  value,
  color,
  bgColor,
  lowCaseTitle,
  bannerType,
  format,
}) => {
  if (value === undefined) {
    return null;
  }
  return (
    <div className="grid grid-flow-col grid-cols-[1fr_0.1fr]">
      <Field
        type={type}
        value={value}
        color={color}
        bgColor={bgColor}
        lowCaseTitle={lowCaseTitle}
        bannerType={bannerType}
      />
      {shouldShowSeparator(type) && (
        <Separator type={getSeparatorType(type, format)} color={color} />
      )}
    </div>
  );
};

const shouldShowSeparator = (type: BoxType) => type !== 'seconds';

const getSeparatorType = (type: BoxType, format: TimerFormat) => {
  if (format === 'dd/hh:mm:ss') {
    return type === 'days' ? 'slash' : 'colon';
  }

  return 'colon';
};

export default memo(Box);
