import { mergeClassnames } from '@heathmont/moon-core-tw';

type Props = {
  backgroundImage?: string;
};

const Container: FC<Props> = ({ children, backgroundImage }) => (
  <div
    className={mergeClassnames(
      'relative flex flex-col landscape:height2xs:h-auto justify-end gap-2  overflow-hidden bg-[#0F0025] rtl:[direction:rtl] rounded-2xl',
      'rem:min-h-[410px] rem:max-w-[396px] p-5',
      'sm:rem:min-h-[300px] sm:rem:max-w-[250px] sm:px-4 sm:py-5 h-full',
      'lg:rem:min-h-[400px] lg:rem:max-w-[350px] lg:px-6 lg:py-7',
      'xl:rem:min-h-[514px] xl:rem:max-w-[496px] xl:px-8 2xl:py-9',
    )}
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    {children}
  </div>
);

export default Container;
