import useSite from '~/client/hooks/useSite';
import { useGameProvidersQuery } from '~/shared/graphql/home/GameProvidersQuery.generated';
import getGameProvidersVariables from '~/shared/graphql/home/helpers/getGameProvidersVariables';
import optimisticProviders from './optimisticProviders';

const useGameProviders = () => {
  const { site } = useSite();
  const variables = getGameProvidersVariables(site);
  const { data, loading } = useGameProvidersQuery({ variables });
  const providers = loading
    ? optimisticProviders
    : data?.casino?.menuItems?.edges ?? [];
  return { providers, loading };
};

export default useGameProviders;
