import { mergeClassnames } from '@heathmont/moon-core-tw';
import useSite from '~/client/hooks/useSite';
import PlayNowButton from './PlayNowButton';

type Props = {
  href?: string;
  bgColor?: string;
  name?: string;
  provider?: string;
};

const Bottom: FC<Props> = ({
  href = '',
  bgColor = '',
  name = '',
  provider = '',
}) => {
  const { isBitcasino } = useSite();

  return (
    <div className="flex items-center  justify-between bg-piccolo p-4 text-goten">
      <div>
        <p
          className={mergeClassnames(
            'font-semibold text-goten',
            isBitcasino && 'font-headline font-light',
          )}
        >
          {name}
        </p>
        <span className="text-moon-10-caption font-medium uppercase text-goten">
          {provider}
        </span>
      </div>
      <PlayNowButton href={href} bgColor={bgColor} />
    </div>
  );
};

export default Bottom;
