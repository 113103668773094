import useGameCardSize from '~/client/shared/game/GameCard/useGameCardSize';
import GameCarousel from '~/client/shared/game/GameCarousel';
import Lazy from '~/client/shared/LazyLoad/Lazy';
import CarouselPlaceholder from '~/client/shared/LazyLoad/Placeholder/CaroulselPlaceholder';

type Props = { menuItem: string };

const GamesMenuItem: FC<Props> = ({ menuItem }) => {
  const { width, height } = useGameCardSize();
  const { href, titleId } = getDetails(menuItem);
  const placeholder = (
    <CarouselPlaceholder
      width={width}
      height={height}
      titleId={titleId}
      href={href}
    />
  );
  return (
    <Lazy placeholder={placeholder}>
      <GameCarousel
        menuItem={menuItem}
        titleId={titleId}
        href={href}
        placeholder={placeholder}
        sourcePage="front_page"
        sliderName="best_table_games"
      />
    </Lazy>
  );
};

const getDetails = (menuItem: string) => {
  switch (menuItem) {
    case 'slots':
      return {
        href: '/categories/video-slots',
        titleId: 'casino.menu_item.slots',
      };
    case 'baccarat':
      return {
        href: '/categories/live-baccarat',
        titleId: 'casino.menu_item.live-baccarat',
      };
    case 'blackjack':
      return {
        href: '/categories/live-blackjack',
        titleId: 'casino.menu_item.live-blackjack',
      };
    case 'roulette':
      return {
        href: '/categories/live-roulette',
        titleId: 'casino.menu_item.live-roulette',
      };
    default:
      return {
        href: '/categories/live-game-shows',
        titleId: 'casino.menu_item.live-game-shows',
      };
  }
};

export default GamesMenuItem;
