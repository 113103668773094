import { Button } from '@heathmont/moon-base-tw';
import { ArrowsTopRight } from '@heathmont/moon-icons-tw';
import useCashierLink from '~/client/hooks/feature/useCashierLink';
import useFeature from '~/client/hooks/feature/useFeature';
import Translate from '~/client/shared/i18n/Translate';
import applyImgixToUrl from '~/client/shared/images/imgix/applyImgixToUrl';
import NextLink from '~/client/shared/NextLink';
import TailwindThemeProvider from '~/client/shared/ThemeProviders/TailwindThemeProvider';
import Container from '../Container';
import { PromotionFragment } from '@/shared/graphql/PromotionFragment.generated';

type Props = {
  promotion?: PromotionFragment;
  preload?: boolean;
  isMobile?: boolean;
};

const getBgImgWidth = (isMobile?: boolean): number => (isMobile ? 396 : 496);
const getBgImgHeight = (isMobile?: boolean): number => (isMobile ? 410 : 514);

const Card = ({ promotion, isMobile }: Props) => {
  const isBcIoLoggedInBannerButtonsEnabled = useFeature(
    'isBcIoLoggedInBannerButtonsEnabled',
  );
  const bgImgWidth = getBgImgWidth(isMobile);
  const bgImgHeight = getBgImgHeight(isMobile);
  const bcIoPromotion = 'panda-promotion-card-2';
  const { handle = '', title, summary, smallImage = '' } = promotion ?? {};
  const isBcIoPromotionEnabled =
    handle === bcIoPromotion && isBcIoLoggedInBannerButtonsEnabled;
  const { open: openCashier } = useCashierLink();
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    if (openCashier) {
      openCashier();
    }
  };

  const bgImg =
    applyImgixToUrl({
      src: smallImage,
      w: bgImgWidth,
      h: bgImgHeight,
      useDpr3x: true,
    }) || smallImage;

  return (
    <Container backgroundImage={bgImg}>
      <div className="relative z-5 flex flex-col gap-2">
        <div className="text-goten text-center text-2xl xl:text-[32px] xl:leading-8 lg:text-[24px] lg:leading-6 md:text-[18px] md:leading-4 font-medium font-headline">
          {title}
        </div>
        <div className="text-goten text-center text-sm font-normal px-14 md:px-0">
          {summary}
        </div>
        {isBcIoPromotionEnabled && (
          <div className="mt-2 grid grid-cols-2 gap-2">
            <Button
              onClickCapture={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
              ) => handleClick(event)}
            >
              <Translate id="bcio.transfer_money" />
            </Button>
            <TailwindThemeProvider colorScheme="dark">
              <NextLink href={promotion?.link || '/bcio-beta'}>
                <Button
                  variant="outline"
                  className="w-full"
                  iconRight={<ArrowsTopRight />}
                >
                  <Translate id="general.banner.bcio-beta-get-started" />
                </Button>
              </NextLink>
            </TailwindThemeProvider>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Card;
