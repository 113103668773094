type Props = { title: string };

const Title: FC<Props> = ({ title }) => (
  <div className="absolute bottom-0 py-2 w-full backdrop-brightness-90">
    <h2 className="font-headline text-center text-moon-32 font-medium text-goten">
      {title}
    </h2>
  </div>
);

export default Title;
