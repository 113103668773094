import CustomCarousel from '~/client/shared/Carousel/CustomCarousel';
import GameCard from '~/client/shared/game/GameCard';
import PageSection from '~/client/shared/PageSection';
import useRecommendedGamesCarousel from './hooks/useRecommendedGamesCarousel';

type Props = { placeholder?: JSX.Element };

const RecommendedGamesCarousel: FC<Props> = ({ placeholder }) => {
  const { edges, loading, error, titleId, href } =
    useRecommendedGamesCarousel();

  if (loading) {
    return <>{placeholder}</>;
  }

  if (!edges?.length || error) {
    return null;
  }

  const items = edges?.map(({ node }) => {
    if (!node) {
      return null;
    }
    return <GameCard key={node.id} game={node} big sourcePage="front_page" />;
  });

  return (
    <PageSection titleId={titleId} href={href}>
      <div data-testid="recommended-games">
        <CustomCarousel type="game-carousel" items={items ?? []} />
      </div>
    </PageSection>
  );
};
export default RecommendedGamesCarousel;
