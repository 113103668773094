import CustomCarousel from '~/client/shared/Carousel/CustomCarousel';
import PageSection from '~/client/shared/PageSection';
import isListEmpty from '~/shared/utils/isListEmpty';
import Theme from './Theme';
import useThemes from './useThemes';

type Props = { placeholder: JSX.Element };

const Themes: FC<Props> = ({ placeholder }) => {
  const { themes, loading } = useThemes();

  if (loading) {
    return <>{placeholder}</>;
  }
  if (isListEmpty(themes)) {
    return null;
  }

  const items = themes?.map(({ node }, index) => {
    if (!node) {
      return null;
    }
    return <Theme key={node.id} theme={node} index={index + 1} />;
  });
  return (
    <PageSection titleId="casino.menu_item.themes" id="themes">
      <CustomCarousel items={items ?? []} step={1} />
    </PageSection>
  );
};

export default Themes;
