import TestimonialsCmsView from './TestimonialsCmsView';

type Props = {
  content: string;
};

const Card: FC<Props> = ({ content }) => (
  <TestimonialsCmsView
    className="h-64 w-72 md:w-80 [&_.author]:hidden [&_.stars]:absolute [&_.stars]:bottom-4 [&_.stars]:left-4 [&_.stars]:m-0 [&_.stars_img]:inline [&_.testimonial]:relative [&_.testimonial]:h-full [&_.testimonial]:cursor-pointer [&_.testimonial]:rounded-moon-s-sm [&_.testimonial]:bg-gohan [&_.testimonial]:px-5 [&_.testimonial]:pb-5 [&_.testimonial]:pt-[3.25rem] [&_.testimonial]:before:absolute [&_.testimonial]:before:left-4 [&_.testimonial]:before:top-4 [&_.testimonial]:before:block [&_.testimonial]:before:h-6 [&_.testimonial]:before:w-6 [&_.testimonial]:before:bg-[url('https://cdn.coingaming.io/bitcasino/images/icons/quotes.svg')] [&_.text]:m-0 [&_.text]:line-clamp-5 [&_.text]:max-h-24 [&_.text]:text-moon-14 [&_.text]:font-normal [&_.text]:text-trunks [&_.title]:m-0 [&_.title]:mb-3 [&_.title]:line-clamp-2 [&_.title]:max-h-12 [&_.title]:text-moon-18 [&_.title]:font-semibold"
    content={content}
  />
);
export default Card;
