import { mergeClassnames } from '@heathmont/moon-core-tw';

type SeparatorType = 'slash' | 'colon';

type Props = { type?: SeparatorType; color?: string };

const Separator: FC<Props> = ({ type = 'colon', color = 'text-bulma' }) => (
  <p
    className={mergeClassnames(
      'grid h-10 place-items-center px-0.5 py-0 text-moon-10-caption font-medium uppercase',
      color && color,
    )}
  >
    {typeContentMapper[type]}
  </p>
);

const typeContentMapper: Record<SeparatorType, string> = {
  colon: ':',
  slash: '/',
};

export default Separator;
