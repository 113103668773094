import type { GameCategoriesQueryVariables } from '../GameCategoriesQuery.generated';

const getGameCategoriesVariables = (
  site: Site,
): GameCategoriesQueryVariables => ({
  first: 15,
  categoriesFilter: { site, section: 0 },
});

export default getGameCategoriesVariables;
