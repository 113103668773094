import useBcIoLoggedInBannerRequest from './useBcIoLoggedInBannerRequest';

const useBcIoLoggedInBanner = () => {
  const { data, loading } = useBcIoLoggedInBannerRequest();
  const promotions = data?.content?.currentPromotions?.edges?.map(
    (edges) => edges.node,
  );
  return { loading, data: promotions };
};

export default useBcIoLoggedInBanner;
