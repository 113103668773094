import useAgentData from '~/client/hooks/useAgentData';
import Img from '~/client/shared/images/Img';

type Props = { img: string };

const CardImg: FC<Props> = ({ img }) => {
  const { height, width } = useBlogNewsImgSize();
  const src = getArticleImg(img);
  return (
    <div className="overflow-hidden rounded-moon-s-xs">
      <Img src={src} height={height} width={width} fit="crop" />
    </div>
  );
};

const getArticleImg = (img: string) => {
  const [src] = img.split('?');
  return src;
};

const useBlogNewsImgSize = () => {
  const { isMobile } = useAgentData();
  if (isMobile) {
    return { height: 200, width: 300 };
  }
  return { height: 300, width: 450 };
};

export default CardImg;
