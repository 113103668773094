import { useCallback, useEffect, useState } from 'react';

type Args = { totalStep: number; delay: number };

const useBannerCarousel = ({ totalStep, delay }: Args) => {
  const [step, setStep] = useState(0);

  const goToStep = useCallback(
    (nextStep: number) => {
      setStep(nextStep === totalStep ? 0 : nextStep);
    },
    [totalStep],
  );

  useEffect(() => {
    if (delay) {
      const timer = setTimeout(() => {
        goToStep(step + 1);
      }, delay);
      return () => clearTimeout(timer);
    }
    return () => 0;
  }, [delay, step, goToStep]);

  return { step, goToStep };
};

export default useBannerCarousel;
