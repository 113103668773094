import useRate from '~/client/hooks/currency/useRate';
import areBothBTC from './helpers/areBothBTC';
import convert from './helpers/convert';
import convertBtcUnit from './helpers/convertBtcUnit';
import isConvertionNeeded from './helpers/isConvertionNeeded';

type Props = { amount?: number; from: CashierCurrency; to: CashierCurrency };

const useCurrencyConverted = ({ amount = 0, from, to }: Props) => {
  const { rate } = useRate({ to, from });
  if (!isConvertionNeeded(amount, from, to)) {
    return amount;
  }
  if (areBothBTC(from, to)) {
    return convertBtcUnit(amount, from, to);
  }
  if (!rate) {
    return amount;
  }
  return convert(amount, from, to, rate);
};

export default useCurrencyConverted;
