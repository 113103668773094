import useCurrentUser from '~/client/hooks/user/useCurrentUser';

const sevenDaysInSeconds = 604800;

const useCounterTimerLeft = (): number | undefined => {
  const { user } = useCurrentUser();

  if (user?.signupTime === undefined) {
    return undefined;
  }

  const nowTimeInSeconds = Math.round(Date.now() / 1000);
  const offerLeft = sevenDaysInSeconds - (nowTimeInSeconds - user.signupTime);

  return offerLeft;
};

export default useCounterTimerLeft;
