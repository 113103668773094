import Placeholder from '~/client/shared/LazyLoad/Placeholder';
import CarouselPlaceholder from '~/client/shared/LazyLoad/Placeholder/CaroulselPlaceholder';
import rangeArray from '~/shared/utils/rangeArray';
import Card from './BlogNewsCard/Card';

const BlogNewsPlaceholder = () => {
  const placeholderItems = rangeArray(4).map((key) => (
    <Card key={key}>
      <Placeholder />
    </Card>
  ));
  return <CarouselPlaceholder items={placeholderItems} />;
};

export default BlogNewsPlaceholder;
