import useFeature from '~/client/hooks/feature/useFeature';
import usePromoImpression from '~/client/hooks/gtm/usePromoImpression';
import useAgentData from '~/client/hooks/useAgentData';
import useIsRtl from '~/client/hooks/useIsRtl';
import useLanguage from '~/client/hooks/useLanguage';
import useSite from '~/client/hooks/useSite';
import useSidebarStore from '~/client/store/useSidebarStore';
import getLoggedOutQueryVariables from '~/shared/graphql/home/helpers/getLoggedOutQueryVariables';
import { useLoggedOutBannerQuery } from '~/shared/graphql/home/LoggedOutBannerQuery.generated';
import sendPromotionClickToGtm from '~/shared/gtm/sendPromotionClickToGtm';
import useToken from '~/shared/hooks/useToken';
import parseContent from '../helpers/parseContent';

const useLoggedOutBanner = () => {
  const [token] = useToken();
  const { site } = useSite();
  const { code } = useLanguage();
  const { isRtl } = useIsRtl();
  const isBcIoLoggedOutBannerEnabled = useFeature(
    'isBcIoLoggedOutBannerEnabled',
  );
  const bannerHandle = isBcIoLoggedOutBannerEnabled
    ? 'bcio-main-banner-logged-out'
    : 'main-banner-logged-out';
  const variables = getLoggedOutQueryVariables(site, code, bannerHandle);
  const { data } = useLoggedOutBannerQuery({
    variables,
    skip: !!token,
  });
  const { isMobile } = useAgentData();
  const { isOpen: isSibebarOpen } = useSidebarStore();
  const { mainBlock = '', handle = '' } =
    data?.content?.promos?.edges?.[0]?.node ?? {};
  const content = parseContent(mainBlock, isMobile);
  const targetRef = usePromoImpression({ handle });
  const handleClick = () =>
    sendPromotionClickToGtm({ handle, position: `promo-banner` });
  return {
    content,
    site,
    isSibebarOpen,
    targetRef,
    handleClick,
    isRtl,
    isMobile,
  };
};

export default useLoggedOutBanner;
