import { mergeClassnames } from '@heathmont/moon-core-tw';
import type bannerType from '../../types/bannerType';

type Props = {
  bannerType: bannerType;
  bgColor?: string;
  className?: string;
};

const TimerContainer: FC<Props> = ({
  children,
  bannerType,
  bgColor,
  className,
}) => (
  <div
    className={mergeClassnames(
      'grid grid-flow-col gap-4 rounded-moon-s-sm p-1',
      bannerType === 'secondary' && bgColor,
      className && className,
    )}
  >
    {children}
  </div>
);
export default TimerContainer;
