import Button from '~/client/shared/Button';
import NextLink from '~/client/shared/NextLink';
import classNames from '../classNames';

type Props = {
  isMobile?: boolean;
  href?: string;
};

const LoggedOutButton: FC<Props> = ({ children, isMobile, href }) => (
  <NextLink href={href || '/'}>
    <Button
      className={classNames.loggedOutButton}
      variant="primary"
      fullWidth={isMobile}
    >
      {children}
    </Button>
  </NextLink>
);

export default LoggedOutButton;
