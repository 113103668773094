import useGameCardSize from '~/client/shared/game/GameCard/useGameCardSize';
import GameCarousel from '~/client/shared/game/GameCarousel';
import Lazy from '~/client/shared/LazyLoad/Lazy';
import CarouselPlaceholder from '~/client/shared/LazyLoad/Placeholder/CaroulselPlaceholder';

const NewReleases = () => {
  const { width, height } = useGameCardSize();
  const titleId = 'new.releases';
  const href = '/games/new-games-we-love';
  const placeholder = (
    <CarouselPlaceholder
      width={width}
      height={height}
      titleId={titleId}
      href={href}
    />
  );
  return (
    <Lazy placeholder={placeholder}>
      <GameCarousel
        big
        titleId={titleId}
        href={href}
        menuItem="new"
        placeholder={placeholder}
        sourcePage="front_page"
        sliderName="new_releases"
      />
    </Lazy>
  );
};

export default NewReleases;
