import Lazy from '~/client/shared/LazyLoad/Lazy';
import CarouselPlaceholder from '~/client/shared/LazyLoad/Placeholder/CaroulselPlaceholder';
import useThemeSizes from './Theme/helpers/useThemeSizes';
import ThemesView from './Themes';

const Themes: FC = () => {
  const { card } = useThemeSizes();
  const titleId = 'casino.menu_item.themes';
  const placeholder = (
    <CarouselPlaceholder
      length={4}
      width={card.w}
      height={card.h}
      showGameInfo={false}
      titleId={titleId}
    />
  );
  return (
    <Lazy placeholder={placeholder}>
      <ThemesView placeholder={placeholder} />
    </Lazy>
  );
};

export default Themes;
