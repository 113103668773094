import Box from './Box';
import TimerContainer from './Box/styles/TimerContainer';
import type bannerType from './types/bannerType';
import type TimeLeft from '../TimeLeft';
import type TimerFormat from '../TimerFormat';

type Props = {
  timeLeft: TimeLeft;
  color?: string;
  bgColor?: string;
  lowCaseTitle?: boolean;
  bannerType: bannerType;
  format?: TimerFormat;
};

const getBoxes = (timeLeft: TimeLeft) =>
  [
    {
      type: 'days',
      value: timeLeft?.days,
    },
    {
      type: 'hours',
      value: timeLeft?.hours,
    },
    {
      type: 'minutes',
      value: timeLeft?.minutes ?? 0,
    },
    {
      type: 'seconds',
      value: timeLeft?.seconds ?? 0,
    },
  ] as const;

const TimerView: FC<Props> = ({
  timeLeft,
  color,
  bgColor,
  lowCaseTitle,
  bannerType,
  format = 'dd/hh:mm:ss',
}) => {
  const boxes = getBoxes(timeLeft);

  return (
    <TimerContainer bannerType={bannerType} bgColor={bgColor} className="gap-0">
      {boxes.map((box) => (
        <Box
          key={box.type}
          type={box.type}
          value={box.value}
          color={color}
          bgColor={bgColor}
          lowCaseTitle={lowCaseTitle}
          bannerType={bannerType}
          format={format}
        />
      ))}
    </TimerContainer>
  );
};

export default TimerView;
