import type { DOMNode } from 'html-react-parser';
import type Node from '../Node';

const getElement = (nodes: DOMNode[], className: string): DOMNode | null => {
  for (const node of nodes) {
    const { attribs, children } = node as Node;
    if (attribs?.class?.includes(className)) {
      return node;
    }
    if (children) {
      const match = getElement(children, className);
      if (match) {
        return match;
      }
    }
  }
  return null;
};

export default getElement;
