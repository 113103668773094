import useFeature from '~/client/hooks/feature/useFeature';
import { useHasAnyTransactionsQuery } from '../../graphql/hasAnyTransactionsQuery.generated';
import useCounterTimerLeft from '../components/CounterTimer/hooks/useCounterTimerLeft';

const useShouldShowFirstDepositBanner = () => {
  const { data } = useHasAnyTransactionsQuery();
  const offerLeft = useCounterTimerLeft();
  const isFirstDepositBannerEnabled = useFeature('isFirstDepositBannerEnabled');

  const hasAnyTransactions =
    data?.banking?.hasAnyTransactions?.hasAnyTransactions;

  if (hasAnyTransactions === undefined) {
    return undefined;
  }

  if (data === undefined || offerLeft === undefined || offerLeft <= 0) {
    return false;
  }

  return hasAnyTransactions === false && Boolean(isFirstDepositBannerEnabled);
};

export default useShouldShowFirstDepositBanner;
