import { Button } from '@heathmont/moon-core-tw';
import Translate from '~/client/shared/i18n/Translate';
import NextLink from '~/client/shared/NextLink';

type Props = { showClaimReward?: boolean };

const ClaimWelcomeBonus: FC<Props> = ({ showClaimReward }) => {
  if (!showClaimReward) {
    return null;
  }

  return (
    <NextLink href="/profile/rewards">
      <Button variant="secondary">
        <Translate id="modals.welcome.claim_bonus" />
      </Button>
    </NextLink>
  );
};

export default ClaimWelcomeBonus;
