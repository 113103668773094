import Translate from '~/client/shared/i18n/Translate';

type Props = {
  titleId: string;
};

const NoCryptoBannerTitle: FC<Props> = ({ titleId }) => (
  <div className="font-headline text-goten 3xl:w-40 3xl:shrink-0">
    <Translate id={titleId} className="text-xl 3xl:text-2xl" />
  </div>
);

export default NoCryptoBannerTitle;
