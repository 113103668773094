import { Fragment } from 'react';
import type { DOMNode } from 'html-react-parser';
import useFeature from '~/client/hooks/feature/useFeature';
import Img from '~/client/shared/images/Img';
import getImgixSrc from '~/client/shared/images/imgix/getImgixSrc';
import type Node from '../../Node';

type Props = {
  node: DOMNode;
  isMobile?: boolean;
  preload?: boolean;
};

const Image: FC<Props> = ({ node, isMobile, preload = false }) => {
  const WIDTH = isMobile ? 477 : 788;
  const HEIGHT = isMobile ? 312 : 516;
  const isBcIoLoggedOutBannerEnabled = useFeature(
    'isBcIoLoggedOutBannerEnabled',
  );
  const isBcIoImgHidden =
    isMobile && isBcIoLoggedOutBannerEnabled ? 'hidden' : undefined;

  const { attribs } = node as Node;

  if (!isMobile && attribs?.class?.includes('mobile')) {
    return <Fragment />;
  }
  if (isMobile && attribs?.class?.includes('desktop')) {
    return <Fragment />;
  }

  const imgixProps = {
    src: attribs?.src,
    className: attribs?.class,
    width: WIDTH,
    height: HEIGHT,
    alt: '',
  };
  const imgixSrc = getImgixSrc(imgixProps);

  return (
    <Img
      width={WIDTH}
      height={HEIGHT}
      className={attribs?.class && isBcIoImgHidden}
      src={imgixSrc}
      preload={preload}
    />
  );
};

export default Image;
