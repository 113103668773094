import getCurrency from './getCurrency';
import isUnit from './isUnit';

const addUnit = (
  amount: number,
  to: string,
  convertUnit: (
    value: number | string,
    currency: string,
    fromUnit: string,
    toUnit: string,
  ) => number,
) => {
  if (!isUnit(to)) {
    return amount;
  }
  const currency = getCurrency(to);
  return convertUnit(amount, currency, currency, to);
};

export default addUnit;
