import { mergeClassnames } from '@heathmont/moon-core-tw';
import type { ColorNames } from '@heathmont/moon-themes';
import useAgentData from '~/client/hooks/useAgentData';
import useSite from '~/client/hooks/useSite';
import Translate from '~/client/shared/i18n/Translate';
import Img from '~/client/shared/images/Img';
import NextLink from '~/client/shared/NextLink';
import type { GameEdgeFragment } from '~/shared/graphql/home/GameCarouselQuery.generated';
import useGames from '../hooks/useGames';

type Props = { themeHandle?: string; color: ColorNames; theme?: string };

const numbers = [
  '[grid-area:one]',
  '[grid-area:two]',
  '[grid-area:three]',
  '[grid-area:four] mt-1',
  '[grid-area:five] mt-1',
  '[grid-area:six] mt-3',
  '[grid-area:seven] mt-2',
];

const ThemeContent: FC<Props> = ({ themeHandle, color }) => {
  const { games } = useGames(themeHandle);
  const { isMobile } = useAgentData();
  const { isBitcasino } = useSite();

  const gamesImages = games?.map(
    ({ node }: GameEdgeFragment, index: number) => (
      <div className={mergeClassnames(numbers[index])} key={node?.id}>
        <NextLink href={`${node?.href}`} key={node?.id}>
          <Img
            className="rounded-moon-s-sm"
            key={node?.id}
            src={node?.thumbUrl}
            alt={node?.name}
          />
        </NextLink>
      </div>
    ),
  );

  return (
    <div
      className={mergeClassnames(
        'grid grid-cols-[9.5rem_4.5rem_4.5rem_7.5rem] grid-rows-[5.5rem_3.5rem_1.5rem_1.5rem_3.5rem] gap-2 [grid-template-areas:"title_title_title_three"_"._six_six_two"_"seven_six_six_two"_"seven_six_six_one"_"seven_five_four_one"]',
        isMobile &&
          'grid-cols-[4.5rem_4.5rem_7.5rem] grid-rows-[3.5rem_1.5rem_1.5rem_3.5rem] [grid-template-areas:"title_title_two"_"._four_two"_"._four_one"_"five_three_one"]',
      )}
      data-testid="images-grid"
    >
      <p
        className={mergeClassnames(
          'left-6 pt-5 text-moon-32 font-semibold [grid-area:title]',
          isMobile && 'pt-0 text-moon-24',
          isBitcasino && 'font-headline font-light',
        )}
        style={{ color }}
        data-testid="theme-title"
      >
        <Translate id={`casino.theme.${themeHandle}`} />
      </p>
      {gamesImages}
    </div>
  );
};

export default ThemeContent;
