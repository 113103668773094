import { mergeClassnames } from '@heathmont/moon-core-tw';
import useSite from '~/client/hooks/useSite';

type Props = {
  borderRadiuses?: string;
};

const BannerContainer: FC<Props> = ({ children, borderRadiuses }) => {
  const { isBitcasino } = useSite();

  return (
    <div
      className={mergeClassnames(
        'relative grid min-h-[19rem] w-full overflow-hidden bg-gohan p-0 rtl:[direction:rtl] md:mx-auto md:my-0 md:bg-goku/moon md:rem:h-[516px]',
        'landscape:height2xs:h-auto',
        borderRadiuses,
        isBitcasino && 'md:bg-[#E9D7D3]',
      )}
    >
      {children}
    </div>
  );
};

export default BannerContainer;
