import type { BlogNewsArticleFragment } from '~/client/components/Home/BlogNews/useBitcasinoNews/BlogNewsQuery.generated';
import NextLink from '~/client/shared/NextLink';
import Card from './Card';
import CardImg from './CardImg';

type Props = { article: BlogNewsArticleFragment };

const BlogNewsCard: FC<Props> = ({ article }) => {
  const href = getHref(article);
  return (
    <NextLink href={href}>
      <Card>
        <CardImg img={article?.smallImage ?? ''} />
        <h5 className="mb-2 line-clamp-2 max-h-14 text-moon-18">
          {article.title}
        </h5>
        <p className="mb-4 line-clamp-3 text-sm text-trunks rem:max-h-[60px]">
          {article.summary ?? ''}
        </p>
        <p className="text-moon-10-caption font-medium uppercase text-trunks">
          {formatPublishDate(article.publishDate)}
        </p>
      </Card>
    </NextLink>
  );
};

const getHref = ({ sectionHandle = '', handle }: BlogNewsArticleFragment) => {
  const parsedSectionHandle = sectionHandle.replace('blog-', '');
  const mainUrl = `/blog/${parsedSectionHandle}/${handle}`;
  return mainUrl;
};

const formatPublishDate = (publishDate = '') => {
  try {
    return new Date(publishDate).toLocaleDateString();
  } catch (error) {
    return new Date().toLocaleDateString();
  }
};

export default BlogNewsCard;
