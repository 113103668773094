import { useState } from 'react';
import useIsStepByStepEnabled from '~/client/components/Auth/Authenticate/AuthSteps/hooks/useIsStepByStepEnabled';
import useRouter from '~/client/hooks/router/useRouter';
import useAgentData from '~/client/hooks/useAgentData';
import useSidebarStore from '~/client/store/useSidebarStore';
import useVideoResource from './useVideoResource';

const useNoCryptoBannerLoggedOut = () => {
  const { setOpen } = useSidebarStore();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const closeVideoModal = () => {
    setOpen(true);
    setIsVideoModalOpen(false);
  };
  const openVideoModal = () => {
    setOpen(false);
    setIsVideoModalOpen(true);
  };
  const { push } = useRouter();
  const videoSource = 'bitcasino/banners/USDT_deposit_April_2023_v2.mp4';
  const { videoRef, videoSrc, handleEnded } = useVideoResource(videoSource);
  const isStepByStepEnabled = useIsStepByStepEnabled();
  const loginRedirectPath = isStepByStepEnabled ? '/authenticate' : '/register';
  const { isMobile } = useAgentData();
  const depositImgSrc = isMobile ? 'mobile' : 'desktop';
  const onDepositBannerClick = () => {
    push({
      pathname: loginRedirectPath,
      query: {
        redirect: '/?c=deposit_options',
      },
    });
  };

  return {
    isVideoModalOpen,
    closeVideoModal,
    openVideoModal,
    videoRef,
    videoSrc,
    handleEnded,
    depositImgSrc,
    onDepositBannerClick,
  };
};

export default useNoCryptoBannerLoggedOut;
