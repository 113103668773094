import useLanguage from '~/client/hooks/useLanguage';
import useSite from '~/client/hooks/useSite';
import { useLoggedInBannerQuery } from '~/shared/graphql/home/LoggedInBannerQuery.generated';
import firstDepositBannerQueryFilter from '../constant/firstDepositBannerQueryFilter';

const useFirstDepositBannerRequest = () => {
  const { code } = useLanguage();
  const { site } = useSite();

  const { data } = useLoggedInBannerQuery({
    variables: {
      first: 1,
      filter: {
        site,
        section: firstDepositBannerQueryFilter.section,
        handle: firstDepositBannerQueryFilter.handle,
        language: code,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return data?.content?.currentPromotions?.edges?.[0]?.node;
};

export default useFirstDepositBannerRequest;
