import { Carousel, Loader } from '@heathmont/moon-core-tw';
import useAgentData from '~/client/hooks/useAgentData';
import useBcIoLoggedInBanner from './hooks/useBcIoLoggedInBanner';
import PandaBanner from './PandaBanner';
import useBannerCarousel from '../LoggedInBanner/BannerCarousel/hooks/useBannerCarousel';

const DELAY = 9000;

const BcIoLoggedInBanner = () => {
  const { isMobile } = useAgentData();
  const { data, loading } = useBcIoLoggedInBanner();

  const items = data?.map((promotion) => (
    <PandaBanner key={promotion?.id} promotion={promotion} preload />
  ));

  const { step } = useBannerCarousel({
    totalStep: items?.length ?? 0,
    delay: isMobile ? 0 : DELAY,
  });

  if (loading) {
    return (
      <div className="grid place-items-center gap-4 ">
        <Loader />
      </div>
    );
  }

  if (items === undefined || data === undefined) {
    return null;
  }

  if (isMobile) {
    return (
      <div data-testid="mobile-bciologgedin-banner" className="-mx-4">
        <Carousel step={1} scrollTo={step}>
          <Carousel.Reel className="gap-3 px-5 scroll-hidden">
            {items.map((item) => (
              <Carousel.Item key={item.key} className="rounded-moon-s-md w-fit">
                {item}
              </Carousel.Item>
            ))}
          </Carousel.Reel>
          <Carousel.Indicators className="relative bottom-0 mt-4 justify-center" />
        </Carousel>
      </div>
    );
  }

  return (
    <div
      className="flex justify-between gap-4"
      data-testid="desktop-bciologgedin-banner"
    >
      {items}
    </div>
  );
};

export default BcIoLoggedInBanner;
