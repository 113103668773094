import NextLink from '~/client/shared/NextLink';
import type { GameCategoryFragment } from '~/shared/graphql/home/GameCategoriesQuery.generated';
import CategoryImage from './CategoryImage';

type Props = { category: GameCategoryFragment };

const Category: FC<Props> = ({ category }) => (
  <NextLink href={`/categories/${category?.handle}`}>
    <div className="relative flex flex-col flex-wrap items-center justify-between rounded-moon-s-sm bg-gohan px-2 pb-3 pt-4 text-center rem:min-h-[140px] rem:w-[100px]">
      <CategoryImage category={category} />
      <p className="font-headline font-medium">{category.name}</p>
    </div>
  </NextLink>
);

export default Category;
