import sendInteractionToGtm from '~/shared/gtm/sendInteractionToGtm';
import classNames from '../classNames';
import getElement from '../helpers/getElement';
import type Node from '../Node';

const sendToGtm = (href?: string) => {
  sendInteractionToGtm({
    eventCategory: 'cms_block_link',
    eventAction: 'cms_block',
    eventInfo: href,
  });
};

const useImageWrapper = (node: Node) => {
  const { attribs } = node;
  const image = getElement(node.children, classNames.imageLogout);

  const handleClick = () => {
    sendToGtm(attribs?.href || '/');
  };

  const href =
    (attribs?.href?.startsWith('/#')
      ? attribs?.href.replace('/#', '/')
      : attribs?.href) || '/';

  return { attribs, image, href, handleClick };
};

export default useImageWrapper;
