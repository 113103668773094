import type { RefObject } from 'react';

type Props = {
  className?: string;
  src?: string;
  controls?: boolean;
  loop?: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  width?: string;
  height?: string;
  videoRef?: RefObject<HTMLVideoElement>;
  onLoadedData?: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  onCanPlayThrough?: (
    event: React.SyntheticEvent<HTMLVideoElement, Event>,
  ) => void;
  onEnded?: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
};

const Video: FC<Props> = ({
  className,
  src,
  controls,
  loop,
  autoPlay,
  muted,
  width,
  height,
  videoRef,
  onCanPlayThrough,
  onLoadedData,
  onEnded,
}) => (
  <video
    className={className}
    loop={loop}
    controls={controls}
    autoPlay={autoPlay}
    muted={muted}
    width={width}
    height={height}
    ref={videoRef}
    onLoadedData={onLoadedData}
    onCanPlayThrough={onCanPlayThrough}
    onEnded={onEnded}
  >
    <source src={src} type="video/mp4" />
  </video>
);

export default Video;
