import { mergeClassnames } from '@heathmont/moon-core-tw';
import useAgentData from '~/client/hooks/useAgentData';
import NoCryptoBannerCardContainer from './NoCryptoBannerCardContainer';

type Props = {
  classNames?: string;
  onBannerClick?: () => void;
};

const NoCryptoBannerCard: FC<Props> = ({
  children,
  classNames,
  onBannerClick,
}) => {
  const { isMobile, isDesktop } = useAgentData();

  return (
    <NoCryptoBannerCardContainer
      onBannerClick={onBannerClick}
      classNames={classNames}
    >
      <div
        className={mergeClassnames(
          'absolute left-0 top-0 z-0 block h-full w-full bg-cover bg-no-repeat',
          isMobile &&
            ' bg-[url(https://cdn.coingaming.io/bitcasino/banners/pattern-mobile.png)]',
          isDesktop &&
            ' bg-[url(https://cdn.coingaming.io/bitcasino/banners/pattern-desktop.png)]',
        )}
      >
        {''}
      </div>
      <div className="z-1 flex flex-col items-center justify-between xl:w-full xl:flex-row">
        {children}
      </div>
    </NoCryptoBannerCardContainer>
  );
};

export default NoCryptoBannerCard;
