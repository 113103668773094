import { Fragment } from 'react';
import { mergeClassnames } from '@heathmont/moon-core-tw';
import parse from 'html-react-parser';
import classNames from '../classNames';
import ImageWrapper from '../ImageWrapper';
import LoggedOutButton from '../LoggedOutButton';
import type Node from '../Node';

const parseContent = (content: string, isMobile?: boolean) =>
  parse(content || '', {
    replace: (node) => replaceContent(node as Node, isMobile),
  });

const replaceContent = (node: Node, isMobile?: boolean) => {
  const { attribs } = node;

  if (!isMobile && attribs?.class?.includes('mobile')) {
    return <Fragment />;
  }
  if (isMobile && attribs?.class?.includes('desktop')) {
    return <Fragment />;
  }

  if (attribs?.class === classNames.loggedOutButton) {
    const buttonText = node.children[0].data;
    return (
      <div
        className={mergeClassnames(
          'grid grid-flow-col gap-1',
          !isMobile && 'w-fit',
        )}
      >
        <LoggedOutButton isMobile={isMobile} href={node.attribs?.href}>
          {buttonText}
        </LoggedOutButton>
      </div>
    );
  }

  if (attribs?.class === classNames.currencyInfoTitle) {
    const titleText = node.children[0].data;
    return (
      <div
        className={mergeClassnames(
          'grid grid-flow-col gap-1',
          !isMobile && 'w-fit',
        )}
      >
        <h4 className="text-moon-12 text-trunks">{titleText}</h4>
      </div>
    );
  }

  if (attribs?.class?.includes(classNames.imageLogout)) {
    return <ImageWrapper node={node} isMobile={isMobile} preload />;
  }

  return node;
};

export default parseContent;
