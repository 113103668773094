import SiteIcon from '~/client/shared/SiteIcon';
import type { GameCategoryFragment } from '~/shared/graphql/home/GameCategoriesQuery.generated';

type Props = { category: GameCategoryFragment };

const CategoryImage: FC<Props> = ({ category }) => {
  const { name, handle = '' } = category;
  return <SiteIcon icon={handle} alt={name} width={52} height={52} />;
};

export default CategoryImage;
