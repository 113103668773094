import useArcadeGamesItem from '~/client/layout/Sidebar/ArcadeGamesItem/hooks';
import CustomCarousel from '~/client/shared/Carousel/CustomCarousel';
import PageSection from '~/client/shared/PageSection';
import Category from './Category';
import useGameCategories from './useGameCategories';

type Props = { placeholder: JSX.Element };

const GameCategories: FC<Props> = ({ placeholder }) => {
  const { categories, loading } = useGameCategories();
  const { handle: arcadeHandle } = useArcadeGamesItem();
  if (loading) {
    return <>{placeholder}</>;
  }
  const items = categories.map(({ node }) => {
    if (!node || node.handle === arcadeHandle) {
      return null;
    }
    return <Category key={node.id} category={node} />;
  });
  return (
    <PageSection
      titleId="casino.games_categories"
      href="/categories"
      id="categories"
    >
      <CustomCarousel items={items ?? []} />
    </PageSection>
  );
};

export default GameCategories;
