import useLanguage from '~/client/hooks/useLanguage';
import useSite from '~/client/hooks/useSite';
import getBlogSectionsString from '~/shared/blog/helpers/getBlogSectionsString';
import { useBlogNewsQuery } from './BlogNewsQuery.generated';

const useBlogNews = () => {
  const { site, siteConfig } = useSite();
  const { code: language } = useLanguage();
  const variables = {
    filter: {
      site,
      language,
      section: getBlogSectionsString(siteConfig),
    },
  };
  const { data, loading } = useBlogNewsQuery({ variables });
  const articles = data?.content?.pages?.edges ?? [];
  return { articles, loading };
};

export default useBlogNews;
