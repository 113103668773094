import Timer from '~/client/shared/Timer';
import useCounterTimerLeft from './hooks/useCounterTimerLeft';

const CounterTimer = () => {
  const offerLeft = useCounterTimerLeft();

  return (
    <div className="grid justify-start gap-1">
      <Timer
        secondsLeft={offerLeft}
        format="dd:hh:mm:ss"
        color="text-goten"
        bgColor="bg-[#24033F]"
        bannerType="secondary"
      />
    </div>
  );
};

export default CounterTimer;
