import trackEvent from '../trackEvent';

type Props = { handle: string; position: string };

const sendPromotionViewToGtm = ({ handle, position }: Props) =>
  trackEvent(
    {
      ecommerce: {
        promoView: {
          promotions: [
            {
              position,
              id: handle,
              name: handle,
              creative: handle,
            },
          ],
        },
      },
    },
    'promotionViews',
  );

export default sendPromotionViewToGtm;
