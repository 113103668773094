import { ArrowsRight } from '@heathmont/moon-icons-tw';
import BannerContainer from '~/client/components/Home/Banner/shared/BannerContainer';
import Translate from '~/client/shared/i18n/Translate';
import Img from '~/client/shared/images/Img';
import DepositButtonDesktop from '~/client/shared/NavBarControls/LoggedInControls/UserControls/DepositLink/DepositButton/DepositButtonDesktop';
import NextLink from '~/client/shared/NextLink';
import CounterTimer from './components/CounterTimer';
import MainBannerContainer from '../../../shared/MainBannerContainer';
import type Props from '../Props';
import usePromoBanner from '../usePromoBanner';

const FirstDepositBanner: FC<Props> = ({ promotion }) => {
  const {
    handle = '',
    title,
    largeImage = '',
    mediumImage = '',
    bannerButtonText,
  } = promotion ?? {};
  const { handleClick, isMobile } = usePromoBanner({
    handle,
    largeImage,
    mediumImage,
  });
  const image = isMobile ? `${mediumImage}?format&w=600` : mediumImage;
  return (
    <BannerContainer>
      <MainBannerContainer className="block md:grid">
        <div className="relative top-7 h-72 md:absolute md:right-0 md:top-0 md:h-full md:w-auto">
          <Img className="-z-1 h-full w-full" src={image} alt="First deposit" />
          <div className="absolute bottom-0 left-0 h-1/2 w-full bg-[radial-gradient(180deg,rgba(62,13,104,0)_0%,rgb(var(--gohan))85.82%)] md:hidden" />
        </div>
        {isMobile && (
          <ArrowsRight className="absolute right-3 top-3 text-[2rem] text-goten" />
        )}
        <div className="relative z-1 -mt-24 grid max-w-xl gap-4 self-center p-4 md:mt-0 md:content-center xl:max-w-[50%] xl:gap-6 xl:p-0 xl:pr-4 2xl:p-0 2xl:pr-14 landscape:height2xs:max-w-[50%] landscape:height2xs:content-center">
          <div className="flex flex-col-reverse items-center gap-4 text-center md:flex md:flex-col md:items-start md:gap-6 md:text-left">
            <h1 className="max-h-24 overflow-hidden break-words text-moon-20 font-semibold text-bulma md:max-h-40 md:text-3xl md:shadow-none 2xl:text-3xl">
              {title}
            </h1>
            <CounterTimer />
          </div>
          <div className="flex flex-col gap-4 md:mb-5 md:flex-row md:gap-7">
            {bannerButtonText !== undefined && (
              <DepositButtonDesktop text={bannerButtonText} />
            )}
            <NextLink
              className="flex cursor-pointer items-center justify-center border-none bg-none text-sm font-semibold text-goten underline"
              href={`/promotions/${handle}`}
              onClick={handleClick}
            >
              <Translate id={`how.it.works`} />
            </NextLink>
          </div>
        </div>
      </MainBannerContainer>
    </BannerContainer>
  );
};

export default FirstDepositBanner;
